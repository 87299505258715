import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Typography } from "@material-ui/core";
import {
    shape,
    imgArraow,
    imgBargraph,
    imgReport,
    imgShape,
    image_shape,
    frameShape,
} from "./assets";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imageHerosectionbackground } from "../../contentmanagement/src/assets";
import Footer from "../../../components/src/Footer.web";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay]);
import 'swiper/swiper-bundle.css';
const configJSON = require("./config");
// Customizable Area End

import LandingPageScreenController, {
    Props,
} from "./LandingPageScreenController.web";

const styles: StyleRules = {
    // Customizable Area Start
    backgroundImageStyle: {
        height: '100%',
        backgroundSize: "cover",
        backgroundRepeat: "round",
        backgroundImage: `url(${imageHerosectionbackground})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        '@media(max-width:959px)':{
            minHeight: 'auto',
        }
    },
    shapeStyle: {
        position: 'absolute',
        top: '540.34px',
        right: '112px',
        '@media(max-width:1600px)':{
            top: '450.29px',
            right: '93.5px'
        },
        '@media(max-width:1280px)':{
            right: '74px',
            top: '363.09px',
        }
    },
    mainHeaderTextStyle: {
        fontSize: '60px',
        fontWeight: 800,
        fontFamily: "Lato",
        textAlign: 'center',
        color: '#fff',
        '@media(max-width:1600px)':{
            fontSize: '50px',
        },
        '@media(max-width:1280px)':{
            fontSize: '40px',
        },
        '@media(max-width:959px)':{
            fontSize:"34px"
        },
        '@media(max-width:768px)':{
            fontSize:"28px"
        }
    },
    futureTextStyle: {
        color: '#00E644',
        fontSize: '60px',
        fontWeight: 800,
        fontStyle: 'italic',
        '@media(max-width:1600px)':{
            fontSize: '50px',
        },
        '@media(max-width:1280px)':{
            fontSize: '40px',
        },
        '@media(max-width:959px)':{
            fontSize:"34px"
        },
        '@media(max-width:600px)':{
            fontSize:"28px"
        }
    },
    titrleTextMainBoxStyle: {
        maxWidth: '1442.25px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media(max-width:1600px)':{
            maxWidth: '1201.25px',
        },
        '@media(max-width:1280px)':{
            maxWidth: '961px',
        },
    },
    seamlesslyStyle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '48px',
        marginTop:"100px",
        '@media(max-width:1600px)':{
            marginTop:"83px",
            gap: '40px',
        },
        '@media(max-width:1280px)':{
            marginTop:"67px",
            gap: '32px',
        },
        '@media(max-width:959px)':{
            marginTop:"70px",
            padding: '0 20px'
        }
    },
    descriptionMainTextStyle: {
        fontSize: '30px',
        fontFamily: "Roboto",
        textAlign: 'center',
        color: '#fff',
        '@media(max-width:1600px)':{
            fontSize: '25px',
        },
        '@media(max-width:1280px)':{
            fontSize: '20px',
        },
        '@media(max-width:600px)':{
            fontSize:"16px"
        }
    },
    groupcoinvesterStyle: {
        height: "243px",
        width: "1164px",
    },
    investorBoxStyle: {
        borderRadius: '77.41px 0px 77.41px 0px',
        minHeight: '303.96px',
        maxWidth: '1350px',
        width: '100%',
        background: '#121212',
        position: 'relative',
        marginTop:'-200px',
        '@media(max-width:1600px)':{
            borderRadius: '64.51px 0px 64.51px 0px',
            minHeight: '253.3px',
            maxWidth: '1125px',
        },
        '@media(max-width:1280px)':{
            borderRadius: '51.61px 0px 51.61px 0px',
            minHeight: '204.25px',
            maxWidth: '900px',
        },
        '@media(max-width:959px)':{
            maxWidth:'90%'
        }
    },
    inverstorBoxWrapper:{
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position: 'absolute',
        bottom: '0px'
    },
    companyBoxStyle: {
        borderRadius: '85.33px 0px 85.33px 0px',
        minHeight: '482.67px',
        maxWidth: '1558.67px',
        width: '100%',
        background: '#121212',
        '@media(max-width:1600px)':{
            borderRadius: '71.11px 0px 71.11px 0px',
            maxWidth: '1298.89px',
            minHeight: '402.22px',
        },
        '@media(max-width:1280px)':{
            borderRadius: '56.89px 0px 56.89px 0px',
            maxWidth: '1039.11px',
            minHeight: '321.78px',
        },
        '@media(max-width:1024px)':{
            maxWidth:'90%'
        }
    },
    companyTextStyle: {
        fontFamily: 'Lato',
        fontSize: '48px',
        fontWeight: 800,
        lineHeight: '57.6px',
        color: 'rgba(255, 255, 255, 1)',
        padding: '87px 0px 46.5px',
        textAlign: 'center',
        '@media(max-width:1600px)':{
            padding: '72.5px 0px 38.75px',
            fontSize: '40px',
            lineHeight: '48px',
        },
        '@media(max-width:1280px)':{
            padding: '51px 0px 26px',
        },
        '@media(max-width:959px)':{
            fontSize:"34px",
            padding: '30px 20px'
        },
        '@media(max-width:600px)':{
            fontSize:"28px"
        }
    },
    companyButtonWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '48px',
        justifyContent: 'center',
        margin: '0px 0px 60px',
        '@media(max-width:1600px)':{
            gap: '40px',
            margin: '0px 0px 50px',
        },
        '@media(max-width:1280px)':{
            gap: '32px',
            margin: '0px 0px 40px',
        },
        '@media(max-width:1024px)':{
            padding: '0 20px'
        },
        '@media(max-width:600px)':{
            flexDirection:'column',
            alignItems:'center'
        }
    },
    companyBtnStyle: {
        width: '300px',
        height: "72px",
        borderRadius: "16px",
        fontSize: '24px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: '#FFFFFF',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: "#0069BC",
        },
        '@media(max-width:1600px)':{
            borderRadius: "13.33px",
            width: '250px',
            height: "60px",
            fontSize: '20px',
        },
        '@media(max-width:1280px)':{
            borderRadius: "10.67px",
            width: '200px',
            height: "48px",
            fontSize: '16px',
        },
        '@media(max-width:600px)':{
            height:'auto'
        }
    },
    swiperInverstor: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide' :{
            marginTop:'20px',
            marginBottom:'20px'
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    swiperFirms: {
        '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
            display: 'none !important'
        },
        '& .swiper-button-next, .swiper-container-rtl .swiper-button-prev': {
            display: 'none !important'
        },
        '& .swiper-slide' :{
            marginTop:'0px',
            marginBottom:'20px'
        },
        '& .swiper-wrapper': {
            height: 'auto'
        }
    },
    containerImageStyle: {
        position: 'relative',
        width: '100%',
        maxWidth: '1519.81px',
        margin: '72px auto',
        display: 'flex',
        alignItems: 'center',
        gap: '300px',
        '@media (max-width: 1600px)': {
            margin: '60px auto 49px',
            maxWidth: '1274.02px',
            gap: '260px',
        },
        '@media (max-width: 1280px)': {
            margin: '40px auto 30px',
            maxWidth: '960px',
            gap: '175.43px'
        },
        '@media (max-width: 1024px)': {
            maxWidth: '85%',
            margin: '70px auto',
            gap: '20px',
            justifyContent: 'space-between',
        },
        '@media (max-width: 600px)': {
            maxWidth: '90%',
            flexWrap: 'wrap'
        }
    },
    frameImgStyle: {
        position: 'absolute',
        top: '52%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '@media (max-width: 1600px)': {
            width: '811.67px',
        },
        '@media (max-width: 1280px)': {
            width: '655.7px',
        },
        '@media (max-width: 1024px)': {
            width: '60%',
        }
    },
    missionLeft: {
        display: 'flex',
        flexDirection: 'column',
        gap: '147.5px',
        marginTop: '17.9px',
        width: '459.91px',
        '@media (max-width: 1600px)': {
            gap: '124.7px',
            marginTop: '15.1px',
            width: '377.01px',
        },
        '@media (max-width: 1280px)': {
            gap: '102.48px',
            marginTop: '12.44px',
            width: '304.57px',
        },
        '@media (max-width: 600px)': {
            width: '100%',
            alignItems: 'center',
            gap: '20px'
        }
    },
    missionBorderVertical: {
        border: '1.05px solid rgba(189, 189, 189, 1)',
        height: '893.84px',
        '@media (max-width: 1600px)': {
            height: '755.37px',
        },
        '@media (max-width: 1280px)': {
            height: '620.7px',
        },
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    missionBox: {
        width: '390px',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        '@media (max-width: 1600px)': {
            width: '329.58px',
            gap: '21.37px',
        },
        '@media (max-width: 1280px)': {
            width: '270.82px',
            gap: '17.57px',
        }
    },
    missionHeading: {
        fontFamily: 'Lato',
        fontSize: '67.46px',
        fontStyle: 'italic',
        fontWeight: 700,
        lineHeight: '80.95px',
        color: '#0069BC',
        '@media (max-width: 1600px)': {
            fontSize: '57.01px',
            lineHeight: '68.41px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '46.85px',
            lineHeight: '56.21px',
        }
    },
    missionBorder: {
        border: "1.05px solid",
        borderImage: 'linear-gradient(to right, rgba(189, 189, 189, 1) 0%, rgba(189, 189, 189, 0) 100%) 1',
        width: '100%'
    },
    missionDescription: {
        fontFamily: 'Roboto',
        fontSize: '25.3px',
        fontWeight: 400,
        lineHeight: '37.95px',
        color: '#222222',
        '@media (max-width: 1600px)': {
            fontSize: '21.38px',
            lineHeight: '32.07px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '17.57px',
            lineHeight: '26.35px',
        }
    },
    premierDigitalBox:{
        background: 'linear-gradient(92.83deg, #0086F0 0%, #0069BC 100%)',
        width: "100%",
        alignItems: 'center',
        color: "#fff",
        fontWeight: 600,
        textAlign: "center",
        fontFamily: "Lato",
        lineHeight: "68px",
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
        padding: '126px 0',
        '@media(max-width:1600px)':{
            padding: '102px 0',
        },
        '@media(max-width:1280px)':{
            padding: '82.22px 0',
        }
    },
    callBtn: {
        borderRadius: "14.51px",
        height: "72px",
        maxWidth: '300px',
        width: '100%',
        fontSize: '21.77px',
        fontFamily: "Roboto",
        color: '#FFFFFF',
        textTransform: 'none',
        fontWeight: 700,
        border: '1px solid rgba(255, 255, 255, 1)',
        '@media(max-width:1600px)':{
            borderRadius: "12.1px",
            height: "60px",
            maxWidth: '250px',
            fontSize: '18.14px',
        },
        '@media(max-width:1280px)':{
            borderRadius: "9.68px",
            height: "48px",
            maxWidth: '200px',
            fontSize: '14.51px',
        },
        '@media(max-width:600px)':{
            height: "fit-content",
            fontWeight: 500,
            fontSize: '16px',
            width:'50%'
        }
    },
    investNowBtn: {
        borderRadius: "14.51px",
        height: "72px",
        maxWidth: '300px',
        width: '100%',
        fontSize: '21.77px',
        fontFamily: "Roboto",
        color: 'rgba(0, 105, 188, 1)',
        textTransform: 'none',
        background: 'rgba(255, 255, 255, 1)',
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 1)",
        },
        '@media(max-width:1600px)':{
            borderRadius: "12.1px",
            height: "60px",
            maxWidth: '250px',
            fontSize: '18.14px',
        },
        '@media(max-width:1280px)':{
            borderRadius: "9.68px",
            height: "48px",
            maxWidth: '200px',
            fontSize: '14.51px',
        },
        '@media(max-width:600px)':{
            height: "fit-content",
            fontWeight: 500,
            fontSize: '16px',
            width:'50%'
        }
    },
    section: {
        scrollSnapAlign: 'start',
        animation: 'fadeIn 0.5s ease-out forwards',
        height: '1080',
        '@media(max-width:1600px)':{
            height: '900px',
        },
        '@media(max-width:1280px)':{
            height: '720px',
        },
        '@media(max-width:1024px)':{
            height: 'auto',
        }
    },
    section1: {
        '@media(max-width:1024px)': {
            height: '720px !important'
        },
        '@media(max-width:400px)': {
            height: '780px !important'
        }
    },
    section3Box: {
        display: 'flex',
        justifyContent: 'center',
        gap: '72px',
        padding: '48px 0 50.67px',
        '@media (max-width: 1600px)': {
            gap: '60px',
            padding: '40px 0 42.22px',
        },
        '@media (max-width: 1280px)': {
            gap: '57.56px',
            padding: '40px 0 16.22px',
        },
        '@media (max-width: 959px)': {
            gap: '70px',
            padding: '0px',
        }
    },
    footer: {
        height: 'auto',
        marginTop: '90px',
        '@media (max-width: 1600px)': {
            marginTop: '75px',
        },
        '@media (max-width: 1280px)': {
            marginTop: '52px',
        },
        '@media (max-width: 959px)': {
            marginTop: '70px',
        }
    },
    premireDigital: {
        fontSize: "57.6px",
        fontWeight: 800,
        fontFamily: 'Lato',
        zIndex: 1,
        '@media(max-width:1600px)': {
            fontSize: "50px",
        },
        '@media(max-width:1280px)': {
            fontSize: "40px",
        },
        '@media(max-width:959px)': {
            fontSize: "34px",
            maxWidth:'85%'
        },
        '@media(max-width:600px)':{
            fontSize:'24px'
        }
    },
    cultivatingLongterm: {
        color: "#222222",
        fontSize: "48px",
        width: '100%',
        height: '200.4px',
        fontWeight: 600,
        fontFamily: 'Lato',
        alignSelf: 'start',
        alignItems: 'center',
        display: 'flex',
        gap: '10px',
        '@media(max-width:1600px)': {
            height: '157.5px',
            fontSize: "44px",
            gap: '8px',
        },
        '@media(max-width:1280px)': {
            height: '126px',
            fontSize: "34px",
            gap: '6px',
        },
        '@media(max-width:1024px)': {
            display: 'block',
            height: 'auto'
        },
        '@media(max-width:959px)': {
            fontSize: "34px",
        },
        '@media(max-width:600px)':{
            fontSize:'24px',
            textAlign:'center'
        }
    },
    ventureInvesting:{ 
        fontSize: "48px",
        fontWeight: 600,
        fontFamily: 'Lato',
        lineHeight: '72px',
        '@media(max-width:1600px)': {
            lineHeight: '67.5px',
            fontSize: "45px",
        },
        '@media(max-width:1280px)': {
            lineHeight: '54px',
            fontSize: "36px",
        },
        '@media(max-width:959px)': {
            fontSize: "24px",
            lineHeight: 'normal'
        },
        '@media(max-width:600px)':{
            fontSize:'18px',
            textAlign:'center'
        }
    },
    arrow:{
        width: '366.98px',
        '@media(max-width:1600px)':{
            width: '305.82px',
        },
        '@media(max-width:1280px)':{
            width: '244.66px',
        },
        '@media(max-width:600px)':{
            width:"200px"
        }
    },
    OurCoInvestors:{ 
        fontSize: '36px',
        padding: '58px 0px 9px 63px',
        fontWeight: 400,
        fontFamily: 'Lato',
        color: '#FFFFFF',
        '@media(max-width:1600px)':{
            fontSize: '30px',
            padding: '48px 0px 7px 52px',
        },
        '@media(max-width:1280px)':{
            fontSize: '24px',
            padding: '38px 0px 6px 42px',
        },
        '@media(max-width:600px)':{
            padding:'24px 16px 16px 32px'
        }
     },
     leveragingWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '38.67px',
        '@media(max-width:1600px)':{
            gap: '33.75px',
        },
        '@media(max-width:1280px)':{
            gap: '27px',
        }
     },
     leveraging:{ 
         fontFamily: 'Roboto',
         fontSize: "25.78px",
         lineHeight: '38.67px',
         fontWeight: 400,
         color: '#828282',
         '@media(max-width:1600px)': {
             fontSize: "22.5px",
             lineHeight: '33.75px',
         },
         '@media(max-width:1280px)': {
            fontSize: "18px",
            lineHeight: '27px',
        },
        '@media(max-width:959px)': {
             fontSize: "16px",
             maxWidth: "none",
         },
     },
    ventureWrapper: {
        width: '100%',
        gap: '85.94px',
        display: 'flex',
        flexDirection: 'column',
        '@media(max-width:1600px)': {
            gap: '75px',
        },
        '@media(max-width:1280px)': {
            gap: '60px',
        },
        '@media(max-width:959px)': {
            gap: '30px',
        },
        '@media(max-width:600px)': {
            gap: '24px'
        }
    },
    diversifyWrapper: {
        gap: '53.22px',
        display: 'flex',
        flexDirection: 'column',
        '@media(max-width:1600px)': {
            gap: '44.35px',
        },
        '@media(max-width:1280px)': {
            gap: '35.48px',
        },
        '@media(max-width:959px)': {
            gap: '30px',
        },
        '@media(max-width:600px)': {
            gap: '24px'
        }
    },
    blueWrapper: {
        gap: '35.26px',
        display: 'flex',
        flexDirection: 'column',
        width:'100%',
        '@media(max-width:1600px)': {
            gap: '31.25px',
        },
        '@media(max-width:1280px)': {
            gap: '25px',
        }
    },
    ventureBlueBox: {
        background: "linear-gradient(60deg, #0086F0 , #0069BC)",
        color: "#FFFF",
        borderRadius: "20px",
        padding:'56.22px 40px',
        alignContent: "center",
        '@media(max-width:1600px)': {
            borderRadius: "17.73px",
            padding:'50.53px 35.45px',
        },
        '@media(max-width:1280px)': {
            borderRadius: "14.18px",
            padding:'40.42px 28.36px',
        },
        '@media(max-width:959px)': {
            maxWidth: "none",
            textAlign:'center',
            marginTop:'20px'
        }
    },
    broadenYourInvestment: {
        fontSize: "66px",
        lineHeight: '99px',
        color: '#FFFFFF', 
        width: '100%',
        fontWeight: 600,
        fontFamily: 'Lato',
        alignSelf: 'start',
        '@media(max-width:1600px)': {
            fontSize: "55px",
            lineHeight: '82.5px',
        },
        '@media(max-width:1280px)': {
            fontSize: "44px",
            lineHeight: '66px',
        },
        '@media(max-width:959px)': {
            fontSize: "34px",
            lineHeight: '46px',
        },
        '@media(max-width:600px)':{
            fontSize:'24px',
            lineHeight: '36px',
            textAlign:'center'
        }
    },
    blueBoxText:{ 
        fontSize: "45.6px",
        lineHeight:"68.4px", 
        fontFamily: 'Lato', 
        color: '#FFFFFF', 
        fontWeight: 600,
        '@media(max-width:1600px)':{
            fontSize: "40px",
            lineHeight:"60px", 
        },
        '@media(max-width:1280px)':{
            fontSize: "32px",
            lineHeight:"48px", 
        },
        '@media(max-width:959px)':{
            fontSize:'22px',
            lineHeight:"34px", 
        },
        '@media(max-width:600px)':{
            fontSize:'18px',
            lineHeight:"28px", 
            textAlign:'center'
        }
    },
    wealthBoxStyle: {
        margin: '90.88px 0px',
        maxWidth: '1521.2px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '90px',
        '@media(max-width:1600px)':{
            margin: '75.82px 0px',
            maxWidth: '1267.67px',
            gap: '75px',
        },
        '@media(max-width:1280px)':{
            margin: '60px 0px',
            maxWidth: '1014.14px',
            gap: '60px',
        },
        '@media(max-width:1024px)':{
            margin:'70px 50px'
        },
        '@media(max-width:600px)':{
            margin:'70px 20px',
            justifyContent:'center'
        }
    },
    cultivatingContainer: {
        display: "flex",
        justifyContent: "center",
        padding: '72px 0px 105.14px',
        '@media(max-width:1600px)':{
            padding: '75px 0px 75px',
        },
        '@media(max-width:1280px)':{
            padding: '40px 0px 20px',
        },
        '@media(max-width:959px)':{
            padding: '0px',
        },
    },
    cultivativeWrapper: {
        maxWidth: '1476px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '60px',
        padding: '0 0 85.12px',
        '@media(max-width:1600px)': {
            maxWidth: '1296.25px',
            padding: '0 0 40.19px',
        },
        '@media(max-width:1280px)': {
            maxWidth: '1037px',
            padding: '0 20px 80px',
        },
        '@media(max-width:959px)': {
            margin: '70px 50px',
            padding: '0px'
        },
        '@media(max-width:600px)': {
            margin: '70px 20px',
            justifyContent: 'center'
        }
    },
    ventureInvestmentWrapper: {
        margin: '72px 0px 66.78px',
        maxWidth: '1476px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '85.65px',
        padding: '132.63px 0 160.46px',
        '@media(max-width:1600px)': {
            margin: '60px 0px 55.65px',
            maxWidth: '1230px',
            gap: '71.3px',
            padding: '110.52px 0 135.22px',

        },
        '@media(max-width:1280px)': {
            margin: '40px 50px 20px',
            maxWidth: '1035px',
            gap: '60px',
            padding: '93px 0 114px',
        },
        '@media(max-width:959px)': {
            margin: '70px 50px',
            padding: '0px'
        },
        '@media(max-width:600px)': {
            margin: '70px 20px',
            justifyContent: 'center'
        }
    },
    diversifyBeyond: {
        fontSize: "54px",
        lineHeight: '81px',
        fontFamily: 'Lato',
        fontWeight: 600,
        color: '#FFFFFF',
        '@media(max-width:1600px)':{
            fontSize: "45px",
            lineHeight: '67.5px',
        },
        '@media(max-width:1280px)':{
            fontSize: "36px",
            lineHeight: '54px',
        },
        '@media(max-width:959px)':{
            fontSize:'30',
            lineHeight: '44px',
        },
        '@media(max-width:600px)':{
            fontSize:'20',
            lineHeight: '34px',
        }
    },
    vantureMainWrapper: {
        display: 'flex',
        gap: '34.14px',
        '@media(max-width:1600px)': {
            gap: '31.25px',
        },
        '@media(max-width:1280px)': {
            gap: '25px',
        },
        '@media(max-width:959px)': {
            flexDirection: 'column',
            alignItems:'center'
        },
    },
    blueBoxWrapper: {
        margin: "auto",
        '@media(max-width:959px)': {
            maxWidth: "none",
        },
    },
    blueBoxBelowText: {
        fontSize: "22.56px",
        lineHeight: '33.84px',
        color: '#828282',
        fontFamily: 'Roboto',
        fontWeight: 500,
        '@media(max-width:1600px)': {
            fontSize: "20px",
            lineHeight: '30px',
        },
        '@media(max-width:1280px)': {
            fontSize: "16px",
            lineHeight: '24px',
        },
        '@media(max-width:959px)': {
            maxWidth: "none",
        },
    },
    barGraph:{
        width:'718.88px', 
        height:'602.85px',
        '@media(max-width:1600px)': {
            width:'599.06px', 
            height:'502.37px',
        },
        '@media(max-width:1280px)': {
            width:'479.25px', 
            height:'401.9px',
        },
        '@media(max-width:1150px)': {
            width:'450px',
            height:"auto",
        },
        '@media(max-width:959px)': {
            height:"auto",
            display:'block',
            margin:'0 auto'
        },
        '@media(max-width:600px)': {
            width:'100%'
        },
    },
    barGraphText: {
        fontFamily: 'Roboto',
        color: '#ffffff',
        fontSize: "22.75px",
        lineHeight: '34.12px',
        fontWeight: 400,
        '@media(max-width:1600px)': {
            fontSize: "18.96px",
            lineHeight: '28.44px',
        },
        '@media(max-width:1280px)': {
            fontSize: "15.17px",
            lineHeight: '22.75px',
        }
    },
    graphWrapper:{
        display:"flex",
        gap:'39.92px',
        alignItems:'flex-start',
        '@media(max-width:1600px)': {
            gap:'33.27px',
        },
        '@media(max-width:1280px)': {
            gap:'26.61px',
        },
        '@media(max-width:959px)': {
            flexDirection:'column',
            width:'100%',
        },
    },
    graphBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '38.39px',
        '@media(max-width:1600px)': {
            gap:'31.99px',
        },
        '@media(max-width:1280px)': {
            gap:'25.59px',
        },
    },
    ventureInvestment: {
        fontSize: "68.45px",
        lineHeight: '102.68px',
        color: '#222222',
        width: '100%',
        fontWeight: 600,
        fontFamily: 'Lato',
        alignSelf: 'start',
        '@media(max-width:1600px)': {
            fontSize: "57.04px",
            lineHeight: '85.57px',
        },
        '@media(max-width:1280px)': {
            fontSize: "48px",
            lineHeight: '72px',
        },
        '@media(max-width:959px)': {
            fontSize: "34px",
            lineHeight: '42px',
        },
        '@media(max-width:600px)': {
            fontSize: '24px',
            lineHeight: '32px',
            textAlign: 'center'
        }
    },
    ventureInvestmentSubtitle: {
        fontSize: "51.34px",
        lineHeight: '77.01px',
        fontFamily: 'Lato',
        color: '#222222',
        fontWeight: 600,
        '@media(max-width:1600px)': {
            fontSize: "42.78px",
            lineHeight: '64.17px',
        },
        '@media(max-width:1280px)': {
            fontSize: "36px",
            lineHeight: '54px',
        },
        '@media(max-width:959px)': {
            fontSize: "26px",
            lineHeight: '34px',
        },
        '@media(max-width:600px)': {
            fontSize: '20px',
            lineHeight: '28px',
            textAlign:'center'
        }
    },
    ventureInvestmentDescription: {
        fontSize: "25.67px",
        lineHeight: '38.5px',
        color: '#828282',
        fontWeight: 400,
        '@media(max-width:1600px)': {
            fontSize: "21.39px",
            lineHeight: '32.09px',
        },
        '@media(max-width:1280px)': {
            fontSize: "18px",
            lineHeight: '27px',
        },
        '@media(max-width:959px)': {
            fontSize: "16px",
            lineHeight: '24px',
        },
        '@media(max-width:600px)': {
            fontSize: '14px',
            lineHeight: '22px',
        }
    },
    reportWrapper:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1476px',
        gap: '16.17px',
        margin: 'auto',
        padding: '48px 0 41px',
        '@media(max-width:1600px)': {
            maxWidth: '1230px',
            gap: '13.47px',
            padding: '40px 0 34.49px',
        },
        '@media(max-width:1280px)': {
            maxWidth: '1035px',
            gap: '11px',
            padding: '40px 0 6.12px',
        },
        '@media(max-width:1024px)': {
            width: '90%',
            padding: '0px'
        }
     },
     reportBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '16.17px',
        '@media(max-width:1600px)': {
            gap: '13.47px',
        },
        '@media(max-width:1280px)': {
            gap: '11px',
        },
     },
     reportImage: {
        width:'100%',
        height: '880.45px',
        '@media(max-width:1600px)': {
            height: '733.71px',
        },
        '@media(max-width:1280px)': {
            height: '598.88px',
        },
        '@media(max-width:1024px)': {
            height: 'auto',
        },
    },
     premireShape1:{
        left: "0px",
        height: "198px",
        position: "absolute",
        top: "0px",
        width: "228px",
        '@media(max-width:600px)': {
            width:"100px",
            height:'auto'
        }
    },
    premireShape2:{
        right: "16px",
        position: "absolute",
        transform: " scaleY(1)",
        height: "198px",
        width: "228px",
        bottom: "0px",
        '@media(max-width:600px)': {
            width:'100px',
            height:'auto'
        }
    },
    sourceBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15.39px',
        '@media(max-width:1600px)': {
            gap: '12.33px',
        },
        '@media(max-width:1280px)': {
            gap: '10px',
        }
    },
    source:{ 
        fontFamily: 'Roboto',
         color: '#828282', 
         fontSize: "26.06px",
         lineHeight: '39.09px',
         fontWeight: 600,
         '@media(max-width:1600px)': {
             fontSize: "21.72px",
             lineHeight: '32.58px',
        },
        '@media(max-width:1280px)': {
            fontSize: "18px",
            lineHeight: '27px',
       },
         '@media(max-width:600px)': {
            fontSize:'16px'
         }
    },
    topTierBox: {
        gap: '60px',
        display: 'flex',
        flexDirection: 'column',
        '@media(max-width:1600px)': {
            gap: '50px',
        },
        '@media(max-width:1280px)': {
            gap: '40px',
        },
    },
    topTier: {
        fontSize: "27px",
        lineHeight: '40.5px',
        fontFamily: 'Roboto',
        color: '#F2F2F2',
        fontWeight: 400,
        '@media(max-width:1600px)': {
            fontSize: "22.5px",
            lineHeight: '33.75px',
        },
        '@media(max-width:1280px)': {
            fontSize: "18px",
            lineHeight: '27px',
        },
        '@media(max-width:600px)': {
            fontSize: '16px'
        }
    },
    landingBtnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        gap: '30px',
        marginTop: '62px',
        '@media(max-width:1600px)': {
            marginTop: '52px',
        },
        '@media(max-width:1280px)': {
            marginTop: '40px',
        },
        '@media(max-width:600px)': {
            marginTop: '20px'
        }
    }
    // Customizable Area End
};

export class HomeScreenWeb extends LandingPageScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const breakPoints = {
            '@0.0': {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            '@0.60': {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            '@0.70': {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            '@0.90': {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            '@1.00': {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            '@1.50': {
              slidesPerView: 5,
              spaceBetween: 20,
            },
          }
        // Customizable Area End
        return (
            // Customizable Area Start
            <div style={{'overflowX': 'hidden'}}>
                <section className={`${classes.section} ${classes.section1}`}>
                    <Box className={classes.backgroundImageStyle}>
                        <AppHeader navigation={this.props.navigation} />
                        <img src={imgShape} className={classes.shapeStyle} />
                        <Box className={classes.titrleTextMainBoxStyle} style={{ position: 'relative' }}>
                            <Box className={classes.seamlesslyStyle}>
                                <Typography className={classes.mainHeaderTextStyle}> Seamlessly enter the world of <span className={classes.futureTextStyle}> Alternative Investments </span> with <span className={classes.futureTextStyle} style={{fontWeight:600}}>Future for Alts.</span></Typography>
                                <Typography className={classes.descriptionMainTextStyle}>The world's only platform offering both, a website and application, for effortless access.</Typography>
                                {this.state.token !== null && <Box className={classes.landingBtnWrapper}>
                                    <Button className={classes.callBtn} onClick={this.handleScheduleCall}>{configJSON.ScheduleCall}</Button>
                                    <Button className={classes.investNowBtn} onClick={this.handleInvestNow}>{configJSON.InvestNow}</Button>
                                </Box>}
                            </Box>
                        </Box>
                        <Box className={classes.inverstorBoxWrapper}>
                            <Box className={classes.investorBoxStyle}>
                                <Typography className={classes.OurCoInvestors}>Our Co-Investors</Typography>
                                <img src={imgArraow} className={classes.arrow} />
                                    <Swiper
                                        slidesPerView={5}
                                        navigation
                                        autoplay={{ delay: 700 }} 
                                        className={classes.swiperInverstor}
                                        speed={1500}
                                        loop
                                        breakpoints={breakPoints}
                                    
                                    >
                                        {this.state.investorDataRecords.map((data: any, index: number) => (
                                            <SwiperSlide style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 'auto',
                                            }}>
                                                <img
                                                    src={data.attributes?.image}
                                                    key={index}
                                                    alt={data.attributes.company_name}
                                                    style={{
                                                        width: '30%',
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                            </Box>
                        </Box>
                    </Box>
                </section>
                <section className={classes.section}>
                <Grid item container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid item xs={12}>
                    <div className={classes.containerImageStyle}>
                        <img src={frameShape} className={classes.frameImgStyle} />
                        <div className={classes.missionLeft}>
                            <div className={classes.missionBox}>
                                <div className={classes.missionHeading}>
                                    {configJSON.mission}
                                </div>
                                <div className={classes.missionBorder} />
                                <div className={classes.missionDescription}>
                                    {configJSON.description1}
                                </div>
                            </div>
                            <div className={classes.missionBox}>
                                <div className={classes.missionHeading}>
                                    {configJSON.motivation}
                                </div>
                                <div className={classes.missionBorder} />
                                <div className={classes.missionDescription}>
                                    {configJSON.description2}
                                </div>
                            </div>
                            <div className={classes.missionBox}>
                                <div className={classes.missionHeading}>
                                    {configJSON.promise}
                                </div>
                                <div className={classes.missionBorder} />
                                <div className={classes.missionDescription}>
                                    {configJSON.description3}
                                </div>
                            </div>
                        </div>
                        <div className={classes.missionBorderVertical} />
                        <div className={classes.missionLeft}>
                            <div className={classes.missionBox}>
                                <div className={classes.missionHeading}>
                                    {configJSON.belief}
                                </div>
                                <div className={classes.missionBorder} />
                                <div className={classes.missionDescription}>
                                    {configJSON.description4}
                                </div>
                            </div>
                            <div className={classes.missionBox}>
                                <div className={classes.missionHeading}>
                                    {configJSON.team}
                                </div>
                                <div className={classes.missionBorder} />
                                <div className={classes.missionDescription}>
                                    {configJSON.description5}
                                </div>
                            </div>
                        </div>
                    </div>
                  </Grid>
                </Grid>
                </section>
                <section className={classes.section}>
                    <Grid item container className={classes.section3Box}>

                    <Grid
                        item
                        xs={12}
                        className={classes.premierDigitalBox}
                    >
                        <img
                            alt="group_shape"
                            src={shape}
                            className={classes.premireShape1}
                        />
                        <Typography className={classes.premireDigital}>
                            {configJSON.PremierDigitalText} <br />
                            <span
                                style={{
                                    fontStyle: "italic",
                                    color: "#00E644",
                                    fontWeight: 600,
                                }}
                            >
                                Alternative Investing.
                            </span>
                        </Typography>
                        <img
                            src={image_shape}
                            alt="group_shape"
                            className={classes.premireShape2}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Box className={classes.companyBoxStyle}>
                                <Typography className={classes.companyTextStyle}>
                                    One <span style={{ color: '#0069BC' }}>Platform, </span>
                                    Multiple <span style={{ color: '#0069BC' }}>Opportunities</span>
                                </Typography>
                                <Box className={classes.companyButtonWrapper}>
                                    <Button className={classes.companyBtnStyle} style={{ backgroundColor: this.state.selectedCompanyType === 'startup' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'startup' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("startup")}>Startup</Button>
                                    <Button className={classes.companyBtnStyle} style={{ backgroundColor: this.state.selectedCompanyType === 'funds' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'funds' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("funds")}>Venture Funds</Button>
                                    <Button className={classes.companyBtnStyle} style={{ backgroundColor: this.state.selectedCompanyType === 'secondary' ? '#0069BC' : 'transparent', border: this.state.selectedCompanyType === 'secondary' ? 'none' : '1px solid #FFFFFF' }} onClick={() => this.getCompanies("secondary")}>Secondaries</Button>
                                </Box>
                                <Box  >
                                    <Swiper
                                        navigation
                                        autoplay={{ delay: 700 }}
                                        speed={1500}
                                        className={classes.swiperFirms}
                                        loop
                                        breakpoints={breakPoints}
                                    
                                     >
                                        {this.state.companyData.map((data: any, index: number) => (
                                            <SwiperSlide style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 'auto',
                                            }}>
                                                <img
                                                    src={data.attributes?.image}
                                                    key={index}
                                                    alt={data.attributes.company_name}
                                                    style={{
                                                        width: '30%'
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                </section>

                <section className={classes.section}>                
                <Grid container className={classes.cultivatingContainer}>
                    <Box className={classes.cultivativeWrapper} >
                        <Typography className={classes.cultivatingLongterm}>
                            Cultivate Long-Term Wealth Generation with{"  "}
                            <span
                                style={{
                                    fontStyle: "italic",
                                    fontWeight: 600,
                                    color: "#0069BC",
                                }}
                            >
                                Venture Investing
                            </span>
                        </Typography>
                        <Box className={classes.vantureMainWrapper}>
                            <Box className={classes.ventureWrapper} >
                                <Typography className={classes.ventureInvesting}>
                                    Venture investing stands distinguished for its steadfast
                                    commitment to long-term.
                                </Typography>
                                <div className={classes.leveragingWrapper}>
                                    <Typography className={classes.leveraging}>
                                        Leveraging our industry expertise and investment focus, <b style={{fontWeight:700}}>we excel
                                            at identifying and accessing exclusive top-tier investment
                                            opportunities</b> that are typically inaccessible.
                                    </Typography>
                                    <Typography className={classes.leveraging}>
                                        We bring these opportunities within your reach, <b>enabling
                                            long-term wealth generation.</b>
                                    </Typography>
                                </div>
                            </Box>
                            <Box className={classes.blueWrapper}>
                                <Box className={classes.ventureBlueBox}>
                                    <Box className={classes.blueBoxWrapper}>
                                    <Typography className={classes.blueBoxText}>
                                        Venture investments can deliver an{" "}
                                        <span style={{ color: "#00EC44", fontStyle: "italic", fontWeight: 600 }}>
                                            average return of 55 times
                                        </span>{" "}
                                        the initial investment over a holding period of approximately
                                        eight years.
                                    </Typography>
                                    </Box>
                                    
                                </Box>
                                <Typography className={classes.blueBoxBelowText}>
                                    Past performance is not indicative of future results. The
                                    conclusive data is driven by analysis of multiple data sets.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                </section>

                <section className={classes.section} style={{background: "linear-gradient(60deg, #0086F0 , #0069BC)"}}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.wealthBoxStyle}>
                        <Typography className={classes.broadenYourInvestment}>
                            Broaden your{" "}
                            <span style={{ color: "#00E644", fontStyle: "italic", fontWeight: 800, }} >
                                Investment Portfolio
                            </span>{" "}
                            by Diversifying
                        </Typography>
                        <Box className={classes.graphWrapper}>
                            <Box className={classes.graphBox}>
                                <img src={imgBargraph} alt="bargraph" className={classes.barGraph} />
                                <Box>
                                    <Typography className={classes.barGraphText}>Hypothetical model portfolios shown above are for illustrative purposes only. Past performance is not indicative of future results</Typography>
                                </Box>
                            </Box>
                            <Box className={classes.diversifyWrapper}>
                                <Typography className={classes.diversifyBeyond}>
                                    Diversify beyond startups, encompassing funds and secondary
                                    investments.
                                </Typography>
                                <div className={classes.topTierBox}>
                                    <Typography className={classes.topTier}>
                                        Accessing top-tier alternative investments enables
                                        diversification and potentially superior risk-adjusted
                                        returns, a strategy employed by large institutional investors
                                        to outperform traditional stock and debt portfolios.
                                    </Typography>
                                    <Typography className={classes.topTier} style={{ fontWeight: 700 }}>
                                        Now, individuals, small institutions, and family offices can
                                        also benefit from this strategy through our platform.
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                </section>

                
                <section className={classes.section}>
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Box className={classes.ventureInvestmentWrapper}>
                        <Typography className={classes.ventureInvestment}>
                            <span style={{ color: "#0069BC", fontStyle: "italic", fontWeight: 700 }} >
                                Venture Investments:{" "}
                            </span>
                            Surpassing PE Funds with Superior Returns
                        </Typography>
                        <Typography className={classes.ventureInvestmentSubtitle}>
                            There has been no better time to start investing in alternative
                            asset classes.
                        </Typography>
                        <Typography className={classes.ventureInvestmentDescription}>
                            Over the past two decades, private markets have consistently
                            outperformed their corresponding public market indexes.<br /> Private
                            equity (PE) has been a prominent outperformer, but venture
                            investing has outperformed private equity (PE) with even higher
                            returns in the last decade.
                        </Typography>
                    </Box>
                </Grid>
                </section>
                <section className={classes.section}>
                    <Box className={classes.reportWrapper}>
                        <Box className={classes.reportBox}>
                            <img src={imgReport} className={classes.reportImage} alt="report" />
                            <Box className={classes.sourceBox}>
                                <Typography className={classes.source}>
                                    Past performance is not indicative of future results.
                                </Typography>
                                <Typography className={classes.source}>
                                    Source : Mckinsey & Company
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </section>
                <section className={`${classes.section} ${classes.footer}`}>
                <Footer navigation={this.props.navigation}/>
                </section>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(HomeScreenWeb);
// Customizable Area End
