import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { imgShape, navLogo } from "../../landingpage/src/assets";
import { imageHerosectionbackground, imageShapeTwo } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { blackBg, blueBg, downConnecter, imgArrows, imgDevice, startupSecondaries } from "./assets";
import Footer from "../../../components/src/Footer.web";

// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImageStyle: {
        flexDirection: 'column',
        minHeight: '871px',
        display: 'flex', 
        backgroundRepeat: "round",
        alignItems: 'center',
        backgroundImage: `url(${imageHerosectionbackground})`,
        backgroundSize: "cover",
        gap: '126px',
        '@media (max-width: 1280px)': {
            minHeight: '670px',
            gap: '70px'
        },
        '@media (max-width: 959px)': {
            minHeight: '570px',
            gap: '52px'
        },
        '@media (max-width: 600px)': {
            minHeight: '449px',
            gap: '26px'
        }
    },
    titleTextBoxStyle: {
        maxWidth: '1170px',
        position: 'relative',
        width: '100%',
        minHeight: '298px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    headerTextBoxStyle: {
        fontSize: '64px',
        maxWidth: '940px',
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        fontWeight: 600,
        width: '90%',
        '@media (max-width: 1280px)': {
            fontSize: '52px',
            maxWidth: '870px',
        },
        '@media (max-width: 959px)': {
            fontSize: '36px',
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
        }
    },
    futureTextBoxStyle: {
        color: '#00E644',
        fontWeight: 700,
        fontStyle: 'italic'
    },
    opportunitieBtnStyle: {
        fontSize: '20px',
        fontFamily: "Roboto",
        fontWeight: 700,
        width: '100%',
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '319px',
        padding: '10px 12px',
        borderRadius: '12px',
        textTransform: 'none',
        height: '60px',
        background: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        }
    },
    allInOneStyle: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        "& .allInOneBox": {
            display: 'flex',
            gap: '170px'
        },
        "& .profileBoxStyle": {
            width: '296px',
            padding: '24px 40px',
            gap: '32px',
            borderRadius: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            background: 'linear-gradient(145.6deg, rgba(0, 230, 68, 0.15) 0%, rgba(0, 230, 68, 0.25) 100%)',
            "&.first": {
                bottom: '-83px'
            }
        },
        '@media (max-width: 959px)': {
            marginBottom: "70px",
            "& .allInOneBox": {
                gap: '60px',
                flexWrap: 'wrap',
                justifyContent: 'center',
                "& img": {
                    display: 'flex'
                }
            },
            "& .profileBoxStyle": {
                padding:'24px 20px',
                "&.first": {
                    bottom: '0 !important'
                }
            },
            "& img": {
                display: 'none'
            }
        },
    },

    containerStyle: {
        display: "flex",
        justifyContent: "center",
        margin: '72px 0px',
        padding: '40px 0px',
        '@media (max-width: 1280px)': {
            padding: '40px 20px',
        },
        '@media (max-width: 959px)': {
            padding: '0px 20px',
            margin: '70px 0px',
        },
    },

    textStyle: {
        wordWrap: 'break-word'
    },

    gatewayChildContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        "& .secondaryMarketImage": {
            maxWidth: '470px',
            boxShadow: '-132px -64px rgba(0, 230, 68, 0.25)'
        },
        "& .secondaryMarketBox": {
            width: '100%',
            maxWidth: '600px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            marginTop:'35px',
            padding: '20px',
            '& ul': {
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                lineHeight: '36px',
                margin: '0px'
            }
        },
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600
        },
        '@media (max-width: 1280px)': {
            "& .gatewayText": {
                fontSize: '42px',
            },
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            flexDirection: 'column',
            "& .secondaryMarketBox": {
                paddingBottom: '0px',
            },
        },
        '@media (max-width: 600px)': {
            "& .secondaryMarketImage": {
                maxWidth: '270px',
                boxShadow: '-50px -36px rgba(0, 230, 68, 0.25)'
            },
            "& .secondaryMarketBox": {
                width: 'auto',
            },
            "& .gatewayText": {
                fontSize: '32px',
            },
        },
    },

    secondaryLiquidity: {
        display: 'flex',
        justifyContent: 'center',
        margin: '80px 0px',
        '@media (max-width: 959px)': {
            margin: '70px 0px',
        }
    },

    childContainerStyleWithBackground: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600,
            color: '#ffffff',
            maxWidth: '870px',
            textAlign:"center"
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            padding: '40px 0',
            "& .gatewayText": {
                fontSize: '32px',
            },
        },
        '@media (max-width: 600px)': {
            "& .gatewayText": {
                fontSize: '24px',
            },
        },
    },

    childContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        flexWrap: 'nowrap',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '40px',
            fontWeight: 600,
            color: '#ffffff',
            maxWidth: '870px',
            textAlign:"center"
        },
        "& .startupStyleBox": {
            display: 'flex',
            gap: '32px',
            marginTop: '12px',
            alignItems: 'flex-start',
            "& .startupStyle": {
                maxWidth: '371px',
                width: '100%',
                padding: '28px 28px 56px',
                gap: '24px',
                borderRadius: '32px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                background: 'rgba(229, 240, 248, 1)',
                "&.second": {
                    background: 'rgba(0, 105, 188, 1)',
                    top: '-64px'
                },
                "&.third": {
                    top: '-128px'
                },
                "& .heading": {
                    wordWrap: 'break-word',
                    fontSize: '32px',
                    fontFamily: 'Lato',
                    fontWeight: 700,
                    color: 'rgba(0, 105, 188, 1)'
                },
                '@media(max-width:959px)':{
                    maxWidth:'none',
                }
            },
        },
        "& .indianStartupImage": {
            maxWidth: '510px',
            width: '100%',
            maxHeight: '384px',
            position: 'absolute',
            right: '8px',
            transform: 'perspective(470px) rotateY(-20deg)'
        },
        '@media (max-width: 1280px)': {
            "& .indianStartupImage": {
                maxWidth: '386px'
            }
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            "& .gatewayText": {
                fontSize: '32px',
            },
            "& .imgArrows": {
                display: 'none'
            },
            "& .startupStyleBox": {
                flexWrap: 'wrap',
                justifyContent: 'center',
                "& .startupStyle": {
                    "&.second": {
                        top: '0 !important'
                    },
                    "&.third": {
                        top: '0 !important'
                    },
                    "& .heading": {
                        fontSize: '26px !important',
                    }
                },
            },
            "&.indianStartup": {
                justifyContent: 'center'
            },
            "& .indianStartupImage": {
                order: 0,
                position: 'relative',
                right: '0px',
                transform: 'none'
            },
        },
        '@media (max-width: 600px)': {
            "& .gatewayText": {
                fontSize: '24px',
            },
            "& .startupStyleBox": {
                "& .startupStyle": {
                    "& .heading": {
                        fontSize: '22px !important',
                    }
                },
            },
        },
    },

    imgChildContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        padding: '64px',
        backgroundImage: `url(${blackBg})`,
        minHeight: '400px',
        backgroundRepeat: 'round',
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600,
            color: '#ffffff'
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            padding: '44px',
            minHeight: 'auto',
            "& .gatewayText": {
                fontSize: '40px',
            },
        },
        '@media (max-width: 600px)': {
            padding: '24px',
            "& .gatewayText": {
                fontSize: '28px',
            },
        },
    },

    discoverBenefitsBox: {
        display: "flex",
        justifyContent: "center",
        padding: '40px 0px',
        '@media (max-width: 959px)': {
            padding: '0px',
        }
    },

    discoverBenefits: {
        maxWidth: '1169px',
        width: '100%',
        flexWrap: "nowrap",
        "& img": {
            width: '367px'
        },
        "& .gatewayText": {
            fontFamily: 'Lato',
            fontSize: '48px',
            fontWeight: 600,
            maxWidth: '630px'
        },
        '@media (max-width: 1200px)': {
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
            justifyContent: 'center',
            "& .gatewayText": {
                fontSize: '40px',
            },
        },
        '@media (max-width: 600px)': {
            padding: '0 24px',
            "& .gatewayText": {
                fontSize: '28px',
            },
            "& img": {
                width: '250px'
            },
        },
    },

    indianStartupStyle: {
        backgroundImage: `url(${startupSecondaries})`,
        width: '100%',
        maxWidth: '1077px',
        height: '464px',
        backgroundSize: 'cover',
        backgroundRepeat: 'round',
        transform: 'perspective(1077px) rotateY(2deg)',
        marginLeft: '30px',
        "& .displayBox": {
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            maxWidth: '606px',
            width: '100%',
            padding: '32px',
            "& .gatewayText": {
                fontFamily: 'Lato',
                fontSize: '48px',
                fontWeight: 600,
                textAlign: 'start'
            },
        },
        '@media (max-width: 1280px)': {
            height: '430px',
            "& .displayBox": {
                maxWidth: '490px',
                padding: '32px 32px 32px 50px'
            },
        },
        '@media (max-width: 959px)': {
            order: 1,
            transform: 'none',
            backgroundRepeat: 'space',
            marginLeft: "0px",
            "& .displayBox": {
                padding: '32px',
                width: 'auto',
                maxWidth: 'none',
                "& .gatewayText": {
                    fontSize: '40px !important',
                },
            },
        },
        '@media (max-width: 600px)': {
            "& .displayBox": {
                padding: '32px',
                width: 'auto',
                maxWidth: 'none',
                "& .gatewayText": {
                    fontSize: '26px !important',
                },
            },
        },
    },

    lastChildContainerStyle: {
        maxWidth: '1169px',
        width: '100%',
        display: 'flex',
        padding: '64px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '24px',
        background: 'black',
        borderRadius: '64px 0px 64px 0px',
        "& .seamlessText": {
            fontFamily: 'Lato',
            textAlign: 'center',
            fontSize: '36px',
            fontWeight: 600,
            color: '#ffffff'
        },
        '@media (max-width: 959px)': {
            padding: '40px',
        },
        '@media (max-width: 600px)': {
            "& .seamlessText": {
                fontSize: '24px',
            },
        }
    },

    lastChildImgContainerStyle: {
        alignItems:"center",
        minHeight: '420px',
        width: '100%',
        display: 'flex',
        flexDirection:"row",
        flexWrap:"nowrap",
        justifyContent: 'center',
        padding: '64px',
        backgroundImage: `url(${blueBg})`,
        backgroundRepeat:"no-repeat",
        gap: "20px",
        "& .allInOneText": {
            fontFamily: 'Lato',
            fontSize: '36px',
            fontWeight: 600,
            maxWidth:"583",
            color: '#ffffff'
        },
        '@media (max-width: 959px)': {
            padding: '40px',
            flexWrap:"wrap",
        },
        '@media (max-width: 600px)': {
            "& .allInOneText": {
                fontSize: '24px',
            },
        }
    },

    lastChildInvestBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: 'black',
        width: '100%',
        background: '#ffffff',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '153px',
        '&:hover': {
            background: '#ffffff',
        },
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06)'
    },
    lastChildSecondBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: '#ffffff',
        width: '100%',
        background: 'black',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '153px',
        border: '1px solid rgba(255, 255, 255, 1)',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06)'
    },

    spanStyle: {
        fontStyle: 'italic',
        color: 'rgba(0, 105, 188, 1)',
        fontWeight: 800
    },

    textFormate: {
        wordWrap: 'break-word'
    },
    leftGroupFrameStyle: {
        borderWidth: '2px 2px 1px 0px',
        borderStyle: 'solid',
        borderColor: '#0069BC',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'start',
        padding: '10px'
    },
    investBtnStyle: {
        borderRadius: '12px',
        fontWeight: 700,
        height: '60px',
        fontFamily: "Roboto",
        color: '#ffffff',
        width: '100%',
        background: 'rgba(0, 105, 188, 1)',
        padding: '10px 12px',
        fontSize: '20px',
        textTransform: 'none',
        maxWidth: '319px',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        }
    }
    // Customizable Area End
};

export class SecondariesInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { liquidityData, startupMarketData, token, secondaryCompanyData, indianStatupData, earlyOpportunityData, secondaryCardData, secondaryMarketData } = this.state;
        const { classes } = this.props;

        const currentDate = new Date();
        const month = currentDate.toLocaleString('default', { month: 'long' });
        const date = month + ' ' + currentDate.getDate() + ',' + currentDate.getFullYear();
        // Customizable Area End
        return (
            // Customizable Area Start
            <Paper style={{ minHeight: '100vh', overflowX: 'hidden' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <Box className={classes.titleTextBoxStyle}>
                        <img src={imgShape} style={{
                            top: '-15px',
                            right: '15px',
                            position: 'absolute',
                        }} />
                        <Typography className={classes.headerTextBoxStyle}>
                            <span className={classes.futureTextBoxStyle}> Redefining </span>Venture Capital Secondary Market
                        </Typography>
                    </Box>
                </Box>

                <Grid container className={classes.containerStyle} style={{ marginTop: '90px' }}>
                    {secondaryMarketData && <Grid container className={classes.gatewayChildContainerStyle}>
                        <img src={secondaryMarketData[0]?.attributes.image} className='secondaryMarketImage' />
                        <Box className='secondaryMarketBox'>
                            <img src={navLogo} style={{ maxWidth: '132px', height: '48px' }} />
                            <Typography className="gatewayText">
                                A Gateway to the <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }}>Secondary Market</span> for Startups and Investors
                            </Typography>
                            <ul>
                                {secondaryMarketData[0]?.attributes.venture_capital_contents.map((item: any) => (
                                    <li >{item.content}</li>
                                ))}
                            </ul>
                        </Box>
                    </Grid>}
                </Grid>

                <Grid container className={classes.containerStyle} style={{ background: 'linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)' }}>
                    <Grid container className={classes.childContainerStyleWithBackground} style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Typography className="gatewayText">
                            We are also the <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 230, 68, 1)' }}>world's leading</span> digital platform for Indian startup secondaries.
                        </Typography>
                        {token === null ?
                            <Typography style={{ fontFamily: 'Roboto', fontSize: '18px', fontWeight: 500, color: '#ffffff', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.navigation.navigate('EmailAccountRegistrationWeb')}>Learn more</Typography> : ''
                        }
                    </Grid>
                </Grid>

                <Grid container className={classes.containerStyle} >
                    <Grid container className={classes.imgChildContainerStyle}>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '48px' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                <Typography style={{ fontSize: '24px', fontFamily: 'Lato', color: '#ffffff', fontWeight: 400 }}>
                                    From paper gains to real returns:
                                </Typography>
                                <Typography className="gatewayText">
                                    Harnessing the power of <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }} >secondary liquidity</span>
                                </Typography>
                            </Box>
                            <Button onClick={this.navigateToLiquidate} className={classes.investBtnStyle} style={{ maxWidth: '153px' }}>
                                Liquidate
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.secondaryLiquidity}>
                    <Grid container className={classes.childContainerStyle} style={{ flexDirection: 'row',justifyContent:"center" }}>
                        <Typography className="gatewayText" style={{ color: "#222222", maxWidth: 'none',padding:'0 20px' }}>
                            Secondary Liquidity = <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 105, 188, 1)' }}>Value Add</span> and <span style={{ fontStyle: 'italic', fontWeight: 800, color: 'rgba(0, 230, 68, 1)' }}>Empowerment</span>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.discoverBenefitsBox}>
                    <Grid container className={classes.discoverBenefits}>
                        <Grid item lg={6} className={classes.leftGroupFrameStyle} >
                            <Typography className="gatewayText">
                                Discover the benefits of <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>secondary liquidity</span> for your financial journey.
                            </Typography>
                            <img src={imageShapeTwo} alt="" />
                        </Grid>

                        <Grid item lg={6} style={{ maxWidth: '705px' }} className="onScroll">
                            {liquidityData && liquidityData.map((dataItem: any, index: any) =>
                                <Box style={{ padding: '10px', display: 'flex', flexDirection: 'column', paddingLeft: '32px', justifyContent: 'center', border: '1px solid #0069BC', borderColor: '#0069BC', borderStyle: 'solid', borderWidth: '2px 0px 1px 1px', maxWidth: '705px', minHeight: '240px',
                                background: index === this.state.activeIndex ? '#0069BC' : ''
                                }} className="boxBg">
                                    <Typography style={{ color: index === this.state.activeIndex ? '#FFFFFF' : '#222222', fontFamily: 'Lato', fontWeight: 800, fontSize: '24px', maxWidth: '506px' }}><ol start={index + 1} style={{ margin: '0px' }}><li>{liquidityData[index]?.attributes.title}</li></ol></Typography>
                                    <ul style={{ fontFamily: 'Roboto', color: index === this.state.activeIndex ? '#FFFFFF' : 'rgba(130, 130, 130, 1)', fontSize: '16px', maxWidth: '485px', fontWeight: 400, display: 'flex', flexDirection: 'column', gap: '8px', }}>
                                        {dataItem?.attributes.contents.map((item: any) => (
                                            <li className={classes.textFormate} >{item.content}</li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </Grid> 
                    </Grid>
                </Grid>

                <Grid container className={classes.containerStyle} >
                    <Grid container className={classes.imgChildContainerStyle}>
                        <Box style={{ maxWidth: '650px', width: '100%', display: 'flex', flexDirection: 'column', gap: '48px' }}>
                            <Typography className="gatewayText">
                                Discover New Frontiers in <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }} >Startup Secondary Investments</span>
                            </Typography>
                            <Button className={classes.investBtnStyle} style={{ maxWidth: '153px' }} onClick={this.navigateToInvest} data-test-id="investBtn">
                                Invest
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.containerStyle} style={{ backgroundColor: 'rgba(242, 242, 242, 1)' }}>
                    <Grid container className={classes.childContainerStyleWithBackground} style={{ flexDirection: 'row' }}>
                        <Box style={{ maxWidth: '670px', width: '100%' }}>
                            <Typography className="gatewayText" style={{ color: '#222222', textAlign: 'start' }}>
                                Now, you have the <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }}>opportunity</span> <br/> to get in early!
                            </Typography>
                        </Box>
                        <Box style={{ maxWidth: '470px', width: '100%' }}>
                            {earlyOpportunityData && <Typography style={{ fontFamily: 'Lato', fontSize: '18px', fontWeight: 400, wordBreak: 'break-word', maxWidth: '470px' }}>
                                {earlyOpportunityData[0]?.attributes.venture_capital_contents[0].content}
                            </Typography>}
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.childContainerStyle}>
                        <Typography className="gatewayText" style={{ color: '#222222' }}>
                            Why invest in <span className={classes.spanStyle} >Startup Secondaries</span>?
                        </Typography>
                        <Grid item style={{ display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={imgArrows} className="imgArrows" alt="" style={{ width: '100%', maxWidth: '800px' }} />
                            {startupMarketData && <Box className="startupStyleBox">
                                <Box className="startupStyle">
                                    <Typography className="heading">
                                        {startupMarketData[1]?.attributes.title}
                                    </Typography>
                                    <Typography style={{ wordWrap: 'break-word', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 400 }} >
                                        {startupMarketData[1]?.attributes.content}
                                    </Typography>
                                </Box>
                                <Box className="startupStyle second">
                                    <Typography className="heading" style={{ color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupMarketData[2]?.attributes.title}
                                    </Typography>
                                    <Typography style={{ wordWrap: 'break-word', fontSize: '18px', fontFamily: 'Roboto', fontWeight: 400, color: '#FFFFFF' }} >
                                        {startupMarketData[2]?.attributes.content}
                                    </Typography>
                                </Box>
                                <Box className="startupStyle third">
                                    <Typography className="heading">
                                        {startupMarketData[0]?.attributes.title}
                                    </Typography>
                                    <Typography style={{ wordWrap: 'break-word', fontWeight: 400, fontSize: '18px', fontFamily: 'Roboto', }} >
                                        {startupMarketData[0]?.attributes.content}
                                    </Typography>
                                </Box>
                            </Box>}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid style={{ display: "none", justifyContent: 'space-around' }}>
                    <Typography style={{ color: "rgb(130, 130, 130)", fontFamily: 'Roboto', fontSize: '25px', fontWeight: 600 }}> Most viewed companies as of <span> {date}</span></Typography>
                </Grid>

                <Grid container className={classes.containerStyle} style={{ display: 'none', gap: '15px', background: "rgba(242, 248, 252, 1)" }} >
                    <Box style={{ maxWidth: '1169px', width: '100%', display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'center' }}>
                        {secondaryCompanyData?.slice(0, 8).map((data: any) => (
                            <Grid style={{ display: 'flex', flexDirection: 'column', borderRadius: "8px", width: '264px', padding: '8px', gap: '20px', background: "rgba(242, 248, 252, 1)", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                                <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', height: '55px' }}>
                                    <Typography style={{ fontFamily: 'Lato', fontWeight: 600, fontSize: '35px', }}>{data.attributes.company.data[0]?.attributes.company_name}</Typography>
                                    <img src={data.attributes.company.data[0]?.attributes.image} style={{ height: '50px', width: '50px' }} />
                                </Grid>
                                <Grid style={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto', color: "rgb(130, 130, 130)" }}>Sector</Typography>
                                    <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', color: 'rgb(0, 230, 68)' }}>{data.attributes.company.data[0]?.attributes.company_sector.sector}</Typography>
                                </Grid>

                                <Grid style={{ display: 'flex', gap: '2px', flexDirection: 'column' }}>
                                    <Typography style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Roboto', color: "rgb(130, 130, 130)" }}>{data.attributes.pre_money_valuation && ("Last Round Est. Valuation")}</Typography>
                                    <Typography style={{ fontWeight: 500, fontSize: '16px', fontFamily: 'Roboto', color: 'black' }}>{data.attributes.pre_money_valuation}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Grid>


                <Grid container className={classes.containerStyle} >
                    {indianStatupData && <Grid container className={`${classes.childContainerStyle} indianStartup`} style={{ flexDirection: 'row', position: 'relative' }}>
                        <Box className={classes.indianStartupStyle}>
                            <Box className="displayBox">
                                <Box>
                                    <Typography className="gatewayText" style={{ color: '#ffffff' }}>Indian</Typography>
                                    <Typography className="gatewayText" style={{ fontWeight: 800, color: 'rgba(0, 230, 68, 1)' }}>Startup Secondaries</Typography>
                                </Box>
                                <pre style={{ fontSize: '18px', fontFamily: 'Roboto', fontWeight: 400, color: '#ffffff', whiteSpace: 'break-spaces', maxWidth: '606px', wordBreak: 'break-word' }}>{indianStatupData[0]?.attributes.venture_capital_contents[0].content}</pre>
                            </Box>
                        </Box>
                        <img src={indianStatupData[0]?.attributes.image} alt="" className="indianStartupImage" />
                    </Grid>}
                </Grid>

                <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                    <Grid container className={classes.childContainerStyle}>
                        <Grid item className={classes.allInOneStyle}>
                            {secondaryCardData && <Box className="allInOneBox">
                                <Box className="profileBoxStyle first">
                                    <img src={secondaryCardData[0]?.attributes.image} style={{ borderRadius: '50%', width: '138px', height: '138px' }} />
                                    <Typography style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'Lato', fontWeight: 600, maxWidth:'216px' }} >
                                        A Founder, Employee, or Early Investor in search of <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }}>Liquidity</span>
                                    </Typography>
                                </Box>
                                <Box className="profileBoxStyle">
                                    <img src={secondaryCardData[1]?.attributes.image} style={{ borderRadius: '50%', width: '138px', height: '138px', maxWidth:'216px'  }} />
                                    <Typography style={{ fontSize: '20px', textAlign: 'center', fontFamily: 'Lato', fontWeight: 600 }} >
                                        An Investor looking for lucrative <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }}>Secondary Opportunities</span>
                                    </Typography>
                                </Box>
                            </Box>}
                            <img src={downConnecter} alt="" style={{ width: '100%', maxWidth: '588px' }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container style={{ background: 'linear-gradient(100.52deg, #0086F0 0%, #0069BC 100%)', padding: '20px', display: 'flex', justifyContent: 'center' }} >
                    <Grid container className={classes.lastChildImgContainerStyle} style={{ position: 'relative' }}>
                       <Grid lg={6} style={{display:"flex",justifyContent:"center"}}>
                       <Typography className="allInOneText" style={{  }}>
                            Our  <span style={{ fontWeight: 800, color: 'rgba(0, 230, 68, 1)', fontStyle: 'italic' }} >all-in-one platform</span> is meticulously designed to cater to both of your unique requirements.
                        </Typography>
                       </Grid>
                       <Grid lg={6} style={{textAlign:"end"}}>

                        <img src={imgDevice} style={{ width: '100%', maxWidth: '455px'}} />
                       </Grid>
                        
                    </Grid>
                </Grid>


                <Grid container className={classes.containerStyle} >
                    <Grid container className={classes.lastChildContainerStyle}>
                        <Box style={{ maxWidth: '800px', width: '100%', display: 'flex', flexDirection: 'column', gap: '48px', alignItems: 'center' }}>
                            <Typography className="seamlessText">
                                A seamless experience  in the dynamic world of <span style={{ fontWeight: 800, color: 'rgba(0, 105, 188, 1)', fontStyle: 'italic' }} >Indian startups.</span>
                            </Typography>
                            <Box style={{ display: 'flex', gap: '30px', maxWidth: '336px', width: '100%' }}>
                                <Button className={classes.lastChildInvestBtnStyle} onClick={this.navigateToInvest}>Invest</Button>
                                <Button className={classes.lastChildSecondBtnStyle} onClick={this.navigateToLiquidate}>Liquidate</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation}/>
            </Paper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(SecondariesInvestmentWeb);
// Customizable Area End
