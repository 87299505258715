//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area Start
import {
  Grid,
  Box, Typography, styled
} from "@material-ui/core";
import { imgShape } from "../../landingpage/src/assets";
import { connectore, imageHerosectionbackground, imageShapeOne, imageShapeThree, imageShapeTwo, textlabel_0, textlabel_1, textlabel_3, textlabel_2, userImageOne, userImageThree, userImageTwo, view_ellipseHalf } from "./assets";
import Footer from "../../../components/src/Footer.web";
import AppHeader from "../../../components/src/AppHeader.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{ overflowX: 'hidden' }}>
          <Loader loading={this.state.loading} />
          <Box sx={Styles.bgImageStyle}>
            <AppHeader navigation={this.props.navigation} />
            <Box sx={Styles.titleTextMainBox}>
              <img src={imgShape} style={{
                position: 'absolute',
                right: '-50px',
                top: '-15px',
              }} />
              <StyledMainTitle> We are the <span style={Styles.futureText}>Future</span> of Alternative Investing</StyledMainTitle>
              <StyledMainSubTitle>Future for Alts is the manifestation of our vision for the evolution of alternative investing.</StyledMainSubTitle>
            </Box>
          </Box>

          <Box sx={Styles.traditionalWrapper}>
            <Box sx={Styles.contentImgBox}>
              <Box style={{ display: 'flex', alignItems: 'self-start', width: '100%' }}>
                <Box sx={Styles.greenCard}>
                  <Box sx={Styles.greenCardBox}>
                    <StyledConnectorCardTitle variant="h6" style={{ color: '#0069BC' }}>
                      Traditional limitation:
                    </StyledConnectorCardTitle>
                    <StyledConnectorCardDescription>
                      Privileged access to alternative investments for institutional investors and fund managers.
                    </StyledConnectorCardDescription>
                  </Box>
                </Box>
                <Box sx={Styles.connectorWrapper}>
                  <img src={connectore} alt="" style={{ width: '100%', height: '100%' }} />
                </Box>
              </Box>
              <Box style={Styles.bottomMainBox}>
                <Box sx={Styles.hiddenDiv}></Box>
                <Box sx={Styles.greenCard}>
                  <Box sx={Styles.blueCardBox}>
                    <StyledConnectorCardTitle variant="h6">
                      Transformation goal:
                    </StyledConnectorCardTitle>
                    <StyledConnectorCardDescription>
                      Open up a world of opportunities for retail investors and family offices.
                    </StyledConnectorCardDescription>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={Styles.weAreParentWrapper}>
            <Box sx={Styles.weAreDrivenWrapper}>
              <Box sx={Styles.weAreTextWrapper}>
                <StyledWeAreTitle>We are driven by our <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>beliefs. </span>
                  We disrupt by <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>enabling</span>.
                </StyledWeAreTitle>
                <Box>
                  <Box sx={Styles.imageShapeTwo}>
                    <img src={imageShapeOne} alt="" style={{ height: '100%', maxHeight: '100%' }} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="scroll-box" sx={{ ...Styles.weAreElementsWrapper, border: this.state.activeIndexScroll !== 0 ? 'none' : '1px solid #0069BC', background: this.state.activeIndexScroll !== 0 ? 'transparent' : 'rgba(0, 105, 188, 0.1)' }}>
                  <StyledWeAreSteps>{this.state.about_us_data[2]?.attributes.title}</StyledWeAreSteps>
                  <StyledWeAreStepDescription>{this.state.about_us_data[2]?.attributes.description}</StyledWeAreStepDescription>
                </Box>
                <Box className="scroll-box" sx={{ ...Styles.weAreElementsWrapper, border: this.state.activeIndexScroll !== 1 ? 'none' : '1px solid #0069BC', background: this.state.activeIndexScroll !== 1 ? 'transparent' : 'rgba(0, 105, 188, 0.1)' }}>
                  <StyledWeAreSteps>{this.state.about_us_data[0]?.attributes.title}</StyledWeAreSteps>
                  <StyledWeAreStepDescription>{this.state.about_us_data[0]?.attributes.description}</StyledWeAreStepDescription>
                </Box>
                <Box className="scroll-box" sx={{ ...Styles.weAreElementsWrapper, border: this.state.activeIndexScroll !== 2 ? 'none' : '1px solid #0069BC', background: this.state.activeIndexScroll !== 2 ? 'transparent' : 'rgba(0, 105, 188, 0.1)' }}>
                  <StyledWeAreSteps>{this.state.about_us_data[1]?.attributes.title}</StyledWeAreSteps>
                  <StyledWeAreStepDescription>{this.state.about_us_data[1]?.attributes.description}</StyledWeAreStepDescription>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={Styles.weAreParentWrapper}>
            <Box sx={Styles.futureWrapper}>
              <Box sx={Styles.futureWrapperTitle}>
                <StyledTitle style={{ fontFamily: 'Lato' }}>Future for Alts is an <span style={{ color: '#0069BC', fontWeight: 800, fontStyle: 'italic' }}>innovative investment platform </span>
                  that has: </StyledTitle>
                <Box>
                  <Box sx={Styles.imageTriangleImage}>
                    <img src={imageShapeTwo} style={{ width: '100%', height: '100%' }} alt="" />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Box className="scroll-box" sx={{ ...Styles.numberedStepsWrapper, background: this.state.activeIndexScroll == 3 && '#0069BC' }}>
                  <StyledNumberedStep style={{ color: 3 === this.state.activeIndexScroll ? '#FFFFFF' : '#222222' }}><ol><li>Seamless and accessible investment across asset classes.</li></ol></StyledNumberedStep>
                </Box>
                <Box className="scroll-box" sx={{ ...Styles.numberedStepsWrapper, background: this.state.activeIndexScroll == 4 && '#0069BC' }}>
                  <StyledNumberedStep style={{ color: 4 === this.state.activeIndexScroll ? '#FFFFFF' : '#222222' }}><ol start={2}><li>Beyond traditional options: Funds, direct investments, and secondaries.</li></ol></StyledNumberedStep>
                </Box>
                <Box className="scroll-box" sx={{ ...Styles.numberedStepsWrapper, background: this.state.activeIndexScroll == 5 && '#0069BC' }}>
                  <StyledNumberedStep style={{ color: 5 === this.state.activeIndexScroll ? '#FFFFFF' : '#222222' }}><ol start={3}><li>All-in-one platform for comprehensive investment solutions.</li></ol></StyledNumberedStep>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={Styles.weAreParentWrapper}>
            <Box sx={Styles.ellipsBox}>
              <Box sx={Styles.stepsWrapper} >
                <Box sx={Styles.first}>
                  <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1 }}>
                    <img src={textlabel_0} style={{ margin: '0px -24px 0px 10px' }} />
                    <img src={textlabel_1} style={{ position: 'absolute', zIndex: 1 }} />
                    <Box sx={Styles.futureBox}>
                      Digital First
                    </Box>
                  </Box>
                  <StyledSteps>{this.state.feature_and_benefit[0]?.attributes.description}</StyledSteps>
                </Box>
                <Box sx={Styles.second}>
                  <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1 }}>
                    <img src={textlabel_0} style={{ margin: '0px -24px 0px 10px' }} />
                    <img src={textlabel_2} style={{ position: 'absolute', zIndex: 1 }} />
                    <Box sx={Styles.futureBox}>
                      Secondary Market
                    </Box>
                  </Box>
                  <StyledSteps>{this.state.feature_and_benefit[1]?.attributes.description}</StyledSteps>
                </Box>
                <Box sx={Styles.third}>
                  <Box style={{ alignSelf: 'center', position: 'relative', zIndex: 1 }}>
                    <img src={textlabel_0} style={{ margin: '0px -24px 0px 10px' }} />
                    <img src={textlabel_3} style={{ position: 'absolute', zIndex: 1 }} />
                    <Box sx={Styles.futureBox}>
                      Top-tier Access
                    </Box>
                  </Box>
                  <StyledSteps>{this.state.feature_and_benefit[2]?.attributes.description}</StyledSteps>
                </Box>
              </Box>
              <Box sx={Styles.benifitsWrapper}>
                <img src={imageShapeThree} alt="" />
                <Box >
                  <StyledBenifitsTitle>Features
                    & Benefits</StyledBenifitsTitle>
                  <StyledBenifitsSubTitle>As the foremost digital platform for alternative investments, Future for Alts offers an unparalleled experience for users. What does this mean for you?</StyledBenifitsSubTitle>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{...Styles.weAreParentWrapper, padding:'0px'}}>
            <Box sx={Styles.lastSectionWrapper}>
              <StyledCeoSectionTitle>Success of any platform is contingent upon the proficiency of the team constructing it.</StyledCeoSectionTitle>
              <StyledCEOBlackTitle>Meet the team constructing this platform with you in mind.</StyledCEOBlackTitle>
              <Box sx={Styles.qualityCEOWrapper} >
                <Box sx={Styles.QualityWrapper} >
                  <Box sx={Styles.qualityCEOTextInfo} >
                    <Box style={{ display: 'flex', flexDirection: 'column', alignContent: "space-between" }}>
                      <Box>
                        <StyledQualityTitle>Experienced</StyledQualityTitle>
                        <StyledLi><ul><li>Decades of expertise</li><li>
                          Investment, product, compliance</li></ul>
                        </StyledLi>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={Styles.qualityCEOTextInfo}>
                    <Box style={{ display: 'flex', flexDirection: 'column', }}>
                      <Box>
                        <StyledQualityTitle>Commitment</StyledQualityTitle>
                        <StyledLi><ul><li>Best opportunities and exceptional results</li><li>
                          Valuable insights and guidance</li></ul>
                        </StyledLi>
                      </Box>

                    </Box>
                  </Box>
                  <Box sx={Styles.qualityCEOTextInfo} >
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <Box>
                        <StyledQualityTitle>Customer-centric</StyledQualityTitle>
                        <StyledLi><ul><li>Superior investment experience</li><li>
                          Meets your unique requirements</li></ul>
                        </StyledLi>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={Styles.CEOWrapper}>
                  <Box>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={Styles.qualityCEOWidth}>
                        <img src={userImageOne} width={"100%"} height={'100%'} alt="" />
                      </Box>
                      <StyledCEO>James Smith</StyledCEO>
                      <StyledCEOQualification>Director, CEO</StyledCEOQualification>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ flexDirection: 'column', display: 'flex' }}>
                      <Box sx={Styles.qualityCEOWidth}>
                        <img src={userImageTwo} width={"100%"} height={'100%'} alt="" />
                      </Box>
                      <StyledCEO>James Smith</StyledCEO>
                      <StyledCEOQualification>Director, CEO</StyledCEOQualification>
                    </Box>
                  </Box>
                  <Box>
                    <Box style={{ flexDirection: 'column', display: 'flex' }}>
                      <Box sx={Styles.qualityCEOWidth}>
                        <img src={userImageThree} width={"100%"} height={'100%'} alt="" />
                      </Box>
                      <StyledCEO>James Smith</StyledCEO>
                      <StyledCEOQualification>Director, CEO</StyledCEOQualification>
                    </Box>
                  </Box>
                </Box>

              </Box>
            </Box>
          </Box>
          <Footer navigation={this.props.navigation} />
        </div>
      </ThemeProvider>
      // Customizable Area End

    );
  }
}
// Customizable Area Start


const StyledCEO = styled(Typography)({
  fontFamily: 'Lato',
  fontSize: '24px',
  fontWeight: 700,
  '@media(max-width:1920px)': {
    fontSize: "30.17px",
    lineHeight: '36.21px',
    marginBottom: "8px"
  },
  '@media(max-width:1600px)': {
    fontSize: "25.15px",
    lineHeight: '30.17px',
    marginBottom: "8px"
  },
  '@media(max-width:1280px)': {
    fontSize: "20.12px",
    lineHeight: "24.14px",
    marginBottom: "8px"
  }
})

const StyledCEOQualification = styled(Typography)({
  color: '#0069BC',
  fontSize: '18px',
  fontFamily: 'Roboto',
  fontWeight: 500,
  textTransform: 'uppercase',
  '@media(max-width:1920px)': {
    fontSize: '22.63px',
    lineHeight: '26.52px'
  },
  '@media(max-width:1600px)': {
    fontSize: '18.86px',
    lineHeight: '22.1px'
  },
  '@media(max-width:1280px)': {
    fontSize: "15.09px",
    lineHeight: "17.68px"
  }
})
const StyledMainTitle = styled(Typography)({
  fontSize: '56px',
  fontWeight: 600,
  fontFamily: "lato",
  textAlign: 'center',
  color: '#fff',
  maxWidth: '550px',
  width: '100%',
  '@media(max-width:1920px)': {
    fontSize: '86.4px',
    lineHeight: '129.6px',
    maxWidth: '830px'
  },
  '@media(max-width:1600px)': {
    fontSize: '72px',
    lineHeight: '108px',
    maxWidth: "692px"
  },
  '@media(max-width:1280px)': {
    fontSize: '58px',
    lineHeight: '87px',
    maxWidth: '560px'
  },
  '@media(max-width:959px)': {
    fontSize: '36px',
    lineHeight: '1.5'
  },
  '@media(max-width:600px)': {
    fontSize: '26px',
  }
})

const StyledMainSubTitle = styled(Typography)({
  fontSize: '20px',
  fontFamily: "Roboto",
  textAlign: 'center',
  color: '#fff',
  padding: '0 20px',
  '@media(max-width:1920px)': {
    fontSize: '26.4px',
    lineHeight: '39.6px'
  },
  '@media(max-width:1600px)': {
    fontSize: '22px',
    lineHeight: '33px'
  },
  '@media(max-width:768px)': {
    fontSize: "16px",
    lineHeight: '1.5'
  }
})

const StyledTitle = styled(Typography)({
  fontSize: '48px',
  fontWeight: 600,
  fontFamily: 'Lato',
  '@media(max-width:1920px)': {
    fontSize: '59.7px',
    lineHeight: '89.55px'
  },
  '@media(max-width:1600px)': {
    fontSize: '49.75px',
    lineHeight: '74.62px'
  },
  '@media(max-width:1280px)': {
    fontSize: '40.03px',
    lineHeight: "60.05px"
  },
  '@media(max-width:959px)': {
    fontSize: '34px',
    lineHeight:'1.5'
  },
  '@media(max-width:600px)': {
    fontSize: '24px',
    padding: '0px'
  }
})

const StyledWeAreTitle = styled(Typography)({
  fontSize: '48px',
  fontWeight: 600,
  fontFamily: 'Lato',
  '@media(max-width:1920px)': {
    fontSize: '61.15px',
    lineHeight: '91.73px'
  },
  '@media(max-width:1600px)': {
    fontSize: '50.96px',
    lineHeight: '76.44px'
  },
  '@media(max-width:1280px)': {
    fontSize: '40.77px',
    lineHeight: "61.15px"
  },
  '@media(max-width:959px)': {
    fontSize: '34px',
    lineHeight:'1.5'
  },
  '@media(max-width:600px)': {
    fontSize: '24px',
    padding: '0px 0px 20px 0px'
  }
})

const StyledBenifitsTitle = styled(Typography)({
  fontSize: '64px',
  fontWeight: 700,
  fontFamily: 'Lato',
  fontStyle: 'italic',
  marginTop: "20px",
  color: '#0069BC',
  lineHeight: "84px",
  width: '100%',
  marginBottom: '24px',
  '@media(max-width:1920px)': {
    fontSize: '82.68px',
    lineHeight:'109.13px',
    width: '96%'
  },
  '@media(max-width:1600px)': {
    fontSize: '68.9px',
    lineHeight:"90.95px",
    width: '96%'
  },
  '@media(max-width: 1280px)': {
    width: '100%',
    fontSize: '55.44px',
    lineHeight: '73.18px',
    marginBottom: '14px'
  },
  '@media(max-width: 1024px)': {
    fontSize: '34px',
    lineHeight: '38px',
    textAlign:'center'
  },
  '@media(max-width: 600px)': {
    fontSize: '24px',
    lineHeight: '30px'
  }
})

const StyledBenifitsSubTitle = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '16px',
  color: '#828282',
  maxWidth: '300px',
  width: '100%',
  right: "100px",
  '@media(max-width:1920px)': {
    fontSize: '20.67px',
    lineHeight: '36.17px',
    maxWidth:'none'
  },
  '@media(max-width:1600px)': {
    fontSize: '17.22px',
    lineHeight: '30.14px'
  },
  '@media(max-width:1280px)': {
    fontSize: '13.86px',
    lineHeight: '24.26px'
  },
  '@media(max-width:1200px)': {
    width: '100%',
    textAlign: 'center',
    margin: '0 auto',
  },
  '@media(max-width: 1024px)': {
    maxWidth:'70%'
  },
  '@media(max-width:768px)':{
      maxWidth:"100%"
  }
})

const StyledSteps = styled(Typography)({
  fontSize: '16px',
  width: '100%',
  fontFamily: 'Roboto',
  color: '#222222',
  '@media(max-width:1920px)': {
    fontSize: '20.67px',
    lineHeight: '31px'
  },
  '@media(max-width:1600px)': {
    fontSize: '17.22px',
    lineHeight: '25.84px'
  },
  '@media(max-width:1280px)': {
    fontSize: '13.86px',
    lineHeight: '20.79px'
  },
  '@media(max-width:1024px)': {
    textAlign: 'center',
    width: '100%'
  }
})

const StyledConnectorCardTitle = styled(Typography)({
  fontWeight: 800,
  fontFamily: 'Lato',
  fontSize: "32px",
  lineHeight: "28.4px",
  '@media(max-width:1920px)': {
    fontSize:'41.93px',
    lineHeight:'50.31px',
  },
  '@media(max-width:1600px)': {
    fontSize:'34.94px',
    lineHeight:'41.93px',
  },
  '@media(max-width:1280px)':{
    fontSize:'27.53px',
    lineHeight:'33.03px',
  },
  '@media(max-width:959px)': {
    fontSize: '28px',
    lineHeight: '32px'
  },
  '@media(max-width:600px)': {
    fontSize: '24px',
    lineHeight: '38px'
  }
})

const StyledConnectorCardDescription = styled(Typography)({
  fontFamily: "roboto",
  marginTop: "24px",
  lineHeight: "27px",
  fontSize: "18px",
  '@media(max-width:1920px)':{
    fontSize:'23.22px',
    lineHeight:'34.84px',
  },
  '@media(max-width:1600px)':{
    fontSize:'19.65px',
    lineHeight:'29.48px'
  },
  '@media(max-width:1280px)':{
    fontSize:'15.72px',
    lineHeight:'23.58px'
  },
  '@media(max-width:959px)': {
    marginTop: '16px',
    fontSize: '16px',
    lineHeight: '20px'
  },
  '@media(max-width:600px)': {
    fontSize: '14px',
    marginTop: '16px',
  }
})

const StyledCeoSectionTitle = styled(Typography)({
  color: '#0069BC',
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'Roboto',
  maxWidth: '970px',
  '@media(max-width:1920px)': {
    fontSize: '30.5px',
    lineHeight: '45.75px',
    maxWidth: 'none'
  },
  '@media(max-width:1600px)': {
    fontSize: '25.42px',
    lineHeight: '38.13px'
  },
  '@media(max-width:1280px)': {
    fontSize: '20.33px',
    lineHeight: '30.5px',
  },
  '@media(max-width:1100px)': {
  },
  '@media(max-width:959px)': {
    textAlign: 'center',
    fontSize: '20px'
  },
  '@media(max-width:768px)': {
    fontSize: '16px'
  }
})

const StyledCEOBlackTitle = styled(Typography)({
  fontWeight: 700,
  fontFamily: 'Lato',
  maxWidth: '970px',
  margin: "10px 0px",
  fontSize: '48px',
  '@media(max-width:1920px)': {
    fontSize: '61px',
    lineHeight: '91.5px',
    maxWidth: '1142px'
  },
  '@media(max-width:1600px)': {
    fontSize: '50.83px',
    lineHeight: '76.25px',
    maxWidth: '1066px'
  },
  '@media(max-width:1280px)': {
    fontSize: '40.67px',
    lineHeight: '61px',
    maxWidth:'850px'
  },
  '@media(max-width:959px)': {
    lineHeight:'1.5',
    textAlign: 'center',
    fontSize: '34px',
  },
  '@media(max-width:600px)': {
    fontSize: '20px',
    padding: '20px 0'
  }
})

const StyledNumberedStep = styled(Typography)({
  fontFamily: 'Lato',
  fontWeight: 800,
  fontSize: '32px',
  maxWidth: '506px',
  '& ol': {
    margin: '0'
  },
  '@media(max-width:1920px)': {
    fontSize: '39.8px',
    lineHeight: '59.7px',
    maxWidth: 'none',
  },
  '@media(max-width:1600px)': {
    fontSize: '33.17px',
    lineHeight: '49.75px'
  },
  '@media(max-width:1280px)': {
    fontSize: '26.69px',
    lineHeight: '40.03px'
  },
  '@media(max-width:959px)': {
    fontSize: '24px'
  },
  '@media(max-width:600px)': {
    fontSize: '18px'
  }
})

const StyledWeAreSteps = styled(Typography)({
  color: '#0069BC',
  fontFamily: 'Lato',
  fontWeight: 800,
  fontSize: '32px',
  '@media(max-width:1920px)':{
    fontSize:'40.77px',
    lineHeight:'48.92px'
  },
  '@media(max-width:1600px)':{
fontSize:'33.97px',
lineHeight:'40.77px'
  },
  '@media(max-width:1280px)':{
    fontSize:'27.18px',
    lineHeight:'32.61px'
  },
  '@media(max-width:768px)': {
    fontSize: '24px'
  }
})

const StyledWeAreStepDescription = styled(Typography)({
  color: '#222222',
  fontFamily: 'Roboto',
  fontSize: '18px',
  '@media(max-width:1920px)':{
    fontSize:'22.93px',
    lineHeight:'34.4px'
  },
  '@media(max-width:1600px)':{
    fontSize:'19.11px',
    lineHeight:'28.67px'
  },
  '@media(max-width:1280px)':{
    fontSize:'15.29px',
    lineHeight:'22.93px'
  },
  '@media(max-width:768px)': {
    fontSize: '16px'
  }
})

const StyledQualityTitle = styled(Typography)({
  color: '#0069BC',
  fontSize: '40px',
  fontWeight: 800,
  fontFamily: 'Lato',
  fontStyle: 'italic',
  '@media(max-width:1920px)': {
    fontSize: '50.83px',
    lineHeight: '76.25px'
  },
  '@media(max-width:1600px)': {
    fontSize: '42.36px',
    lineHeight: '63.54px'
  },
  '@media(max-width:1280px)': {
    fontSize: '33.89px',
    lineHeight: '50.83px',
  },
  '@media(max-width:959px)': {
    fontSize: '30px',
    lineHeight:'1.5'
  },
  '@media(max-width:600px)': {
    fontSize: '24px',
  },
})

const StyledLi = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '20px',
  marginLeft: '-12px',
  '@media(max-width:1920px)': {
    fontSize: '25.42px',
    lineHeight: '38.13px'
  },
  '@media(max-width:1600px)': {
    fontSize: "21.18px",
    lineHeight: '31.77px'
  },
  '@media(max-width:1280px)': {
    fontSize: '16.94px',
    lineHeight: '25.42px'
  },
  '@media(max-width:959px)': {
    fontSize: '18px',
    lineHeight:'1.5'
  },
})

const Styles = {
  qualityCEOWidth: {
    maxWidth: '370px',
    width: '100%',
    '@media(max-width:1920px)': {
      maxWidth: 'none',
      width: '480px',
      height: '385px',
      marginBottom: '12px'
    },
    '@media(max-width:1600px)': {
      width: '400px',
      height: '321px',
      marginBottom: '12px'
    },
    '@media(max-width:1280px)': {
      width: '320px',
      height: '257px',
      marginBottom: '12px'
    },
    '& img': {
      objectFit: 'cover',
      borderRadius: '12px'
    }
  },
  qualityCEOTextInfo: {
    maxWidth: '370px',
    width: '100%',
    '@media(max-width:1920px)': {
      maxWidth: '470px'
    }
  },
  qualityCEOWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    '@media(max-width:959px)': {
      gap: '30px'
    },
  },
  numberedStepsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #0069BC',
    borderColor: '#0069BC',
    borderStyle: 'solid',
    borderWidth: '2px 0px 1px 1px',
    maxWidth: '705px',
    minHeight: '240px',
    '@media(max-width:1920px)': {
      minHeight: "299px",
      boxSizing: 'border-box',
      padding: '0 44px'
    },
    '@media(max-width:1600px)': {
      minHeight: "249.5px",
      boxSizing: 'border-box',
      padding: '0 44px'
    },
    '@media(max-width:1280px)': {
      minHeight: '200px',
      boxSizing: 'border-box',
      padding: '0 22px'
    },
    '@media(max-width:959px)': {
      maxWidth: 'none',
      minHeight: '150px'
    },
    '@media(max-width:600px)': {
      maxWidth: 'none',
      minHeight: '100px'
    },

  },
  traditionalWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems:'center',
    '@media(max-width:1920px)': {
      minHeight:'1080px'
    },
'@media(max-width:1600px)': {
  minHeight:'900px'
},
    '@media(max-width:1280px)': {
      minHeight:'720px',
      
    },
    '@media(max-width:1200px)':{
      minHeight:'auto',
      padding: '155px 0px 189px',
    },
    '@media(max-width:959px)': {
      padding: '70px 0'
    }
  },
  imageShapeTwo: {
    width: '100%',
    '@media(max-width:1920px)': {
      width:'311px',
      height:'305px'
    },
'@media(max-width:1600px)': {
  width:'259px',
  height:'254px'
},
    '@media(max-width:1280px)':{
      width:'207px',
      height:'203px'
    },
    '@media(max-width:959px)': {
      display: 'none'
    }
  },
  imageTriangleImage: {
    width: '100%',
    maxWidth: '366px',
    display: 'block',
    margin: '0 auto',
    '@media(max-width:1920px)': {
      maxWidth: '456px'
    },
    '@media(max-width:1600px)': {
      maxWidth: '380px',
      height: '373px'
    },
    '@media(max-width:1280px)': {
      maxWidth: '300px',
      height: 'unset'
    },
    '@media(max-width:959px)': {
      display: 'none'
    }

  },
  futureWrapperTitle: {
    borderWidth: '2px 2px 1px 0px',
    borderStyle: 'solid',
    borderColor: '#0069BC',
    width: '100%',
    gap: '50px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    '@media(max-width:1920px)': {
      paddingTop: "46px"
    },
    '@media(max-width:1600px)': {
      paddingTop: "38px"
    },
    '@media(max-width:1280px)': {
      paddingTop: '32px'
    },
    '@media(max-width:959px)': {
      padding: '20px 0',
      gap: "0",
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  CEOWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '25px',
    '@media(max-width:1920px)': {
      gap: "45px"
    },
    '@media(max-width:1600px)': {
      gap: '38px'
    },
    '@media(max-width:1280px)':{
      gap:'30px'
    },
    '@media(max-width:1100px)': {
      gap: '50px'
    },
    '@media(max-width:600px)': {
      flexDirection: 'column',
    }
  },
  QualityWrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    gap: '20px',
    '@media(max-width:1280px)': {

    },
    '@media(max-width:991px)': {
      // flexWrap: 'wrap'
    },
    '@media(max-width:768px)': {
      flexDirection: 'column',
      margin: '0 auto'
    }
  },
  lastSectionWrapper: {
    maxWidth: '1169px',
    width: '100%',
    padding: '0 20px',
    '@media(max-width:1920px)': {
      marginBottom:'80px',
      maxWidth: '1531px'
    },
    '@media(max-width:1600px)': {
      maxWidth: '1277.39px'
    },
    '@media(max-width:1280px)': {
      maxWidth: '1021px'
    },
    '@media(max-width:1200px)':{
      marginBottom:'0'
    }
  },
  stepsWrapper: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column',
    marginTop: '54px',
    position: "relative",
    gap: "100px",
    marginBottom: '10px',
    '@media(max-width:1920px)': {
      gap: '140px',
    },
    '@media(max-width:1600px)': {
      gap: '100px',
    },
    '@media(max-width: 1280px)': {
      gap: '68px'
    },
    '@media(max-width:1024px)':{
      alignItems:'center',
      gap:'40px'
    },
    '@media(max-width: 600px)': {
      width: '100%'
    }
  },
  benifitsWrapper: {
    flexDirection: 'column',
    display: 'flex',
    width: '30%',
    position: "relative",
    alignItems: "center",
    marginRight: '-30px',
    "& img": {
      alignSelf: "flex-start",
      marginLeft: '40px',
      width: "94px",
      height: "93px",
    },
    '@media(max-width:1920px)': {
      width: '394px',
      marginRight:'10px',
      "& img": {
        width: '121px',
        height: '120px',
        marginLeft: "-243px",
        alignSelf: "center",
      },
    },
    '@media(max-width:1600px)': {
      width: '328.34px',
      marginRight: '30px',
      "& img": {
        width: '101px',
        height: '100px',
        marginLeft: "-143px",
        alignSelf: "center",
      },
    },
    '@media(max-width: 1280px)': {
      width: '254px',
      marginBottom: "-192px",
      marginRight: '0px',
      "& img": {
        width: '81px',
        height: '80px',
        marginLeft: "-143px",
        alignSelf: "center",
      },
    },
    '@media(max-width:1024px)': {
      width: '100%',
      marginRight: '0',
      marginBottom: '0'
    }
  },
  first: {
    display: 'flex',
    alignItems: 'end',
    gap: '24px',
    justifyContent: 'end',
    marginLeft: "240px",
    width: '600px',
    '@media(max-width:1920px)': {
      marginLeft: '264px',
      gap: "31px",
      maxWidth: '775px',
      width: '100%'
    },
    '@media(max-width:1600px)': {
      marginLeft: '195px',
      gap: "25.84px",
      maxWidth: '646px',
      width: '100%'
    },
    '@media(max-width:1280px)': {
      gap: '20.79px',
      maxWidth: '519px',
      width: '100%',
      marginLeft: '151px'
    },
    '@media(max-width:1100px)': {
      width: '100%',
      marginLeft: '135px'
    },
    '@media(max-width:1024px)': {
      marginLeft: "0px",
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    }
  },
  second: {
    display: 'flex',
    marginLeft: "140px",
    alignItems: 'end',
    gap: '24px',
    justifyContent: 'end',
    width: '674px',
    '@media(max-width:1920px)': {
      marginLeft: '124px',
      gap: "31px",
      maxWidth: '870.83px',
      width: '100%'
    },
    '@media(max-width:1600px)': {
      marginLeft: '98px',
      gap: "25.84px",
      maxWidth: '726px',
      width: '100%'
    },
    '@media(max-width: 1280px)': {
      marginLeft: '81px',
      gap: '20.79px',
      maxWidth: '586px',
      width: '100%'
    },
    '@media(max-width:1100px)': {
      width: '100%'
    },
    '@media(max-width:1024px)': {
      marginLeft: "0px",
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    }
  },
  third: {
    display: 'flex',
    alignItems: 'end',
    gap: '24px',
    width: '774px',
    marginLeft: '40px',
    '@media(max-width:1920px)': {
      marginLeft: '0',
      gap: "31px",
      maxWidth: '994.72px',
      width: '100%'
    },
    '@media(max-width:1600px)': {
      marginLeft: '0',
      gap: "25.84px",
      maxWidth: '829px',
      width: '100%'
    },
    '@media(max-width:1280px)': {
      gap: '20.79px',
      maxWidth: '667px',
      marginLeft: '0',
      width: '100%'
    },
    '@media(max-width: 1100px)': {
      marginLeft: '78px',
      width: '500px'
    },
    '@media(max-width:1024px)': {
      marginLeft: "0px",
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    }
  },
  weAreElementsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    borderRadius: '16px',
    maxWidth: '500px',
    minHeight: '130px',
    gap: '24px',
    '@media(max-width:1920px)':{
      minWidth:'637px',
      minHeight:'229px',
      boxSizing:'border-box'
    },
    '@media(max-width:1600px)':{
      minWidth:'530px',
      minHeight:'192px'
    },
    '@media(max-width:1280px)':{
      minWidth:'424px',
      minHeight:'153px'
    },
    '@media(max-width:1200px)': {
      maxWidth: 'none',
      minWidth:'auto',
      padding: '20px',
      minHeight:'auto',
      gap: "12px"
    },
    '@media(max-width:600px)': {
      minHeight: 'auto'
    }
  },
  weAreParentWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems:'center',
    '@media(max-width:1920px)': {
      minHeight:'1080px'
    },
    '@media(max-width:1600px)': {
      minHeight:'900px'
    },
    '@media(max-width:1280px)': {
      minHeight:'720px',
      paddingLeft: "20px",
      paddingRight: "20px",

    },
    '@media(max-width:1200px)':{
      minHeight:'auto',
      padding: '0px 0px 189px',
    },
    '@media(max-width:959px)': {
      paddingBottom: '70px'
    }
  },
  weAreDrivenWrapper: {
    maxWidth: '1169px',
    width: '100%',
    gap: '50px',
    display: 'flex',
    justifyContent:'space-between',
    '@media(max-width:1920px)': {
      maxWidth:'1545px'
    },
    '@media(max-width:1600px)': {
      maxWidth:'1287.5px'
    },
    '@media(max-width:1280px)': {
      maxWidth:'1030px',
      padding:'0 20px'
    },
    '@media(max-width:959px)': {
      flexDirection: 'column',
      gap: "0",
    }
  },
  futureWrapper: {
    maxWidth: '1169px',
    width: '100%',
    display: 'flex',
    gap: '0',
    '@media(max-width:1920px)': {
      height: '897px',
      maxWidth: '1536px'
    },
    '@media(max-width:1600px)': {
      height: '748.5px',
      maxWidth: '1280px'
    },
    '@media(max-width:1280px)': {
      height: '600px',
      maxWidth: '1030px',
      padding:'0 20px'
    },
    '@media(max-width:959px)':{
      height:'100%',
    },
    '@media(max-width:768px)': {
      flexDirection: 'column',
    },
  },
  weAreTextWrapper: {
    maxWidth: '615px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '64px',
    '@media(max-width:1920px)':{
      maxWidth:'782px'
    },
    '@media(max-width:1600px)':{
      maxWidth:'652px'
    },
    '@media(max-width:1280px)':{
      maxWidth:'524px'
    },
    '@media(max-width:1024px)': {
      maxWidth: 'none',
      textAlign: 'center'
    },
    '@media(max-width:768px)': {
      gap: "16px"
    }
  },
  greenCard: {
    width: '50%',
    '@media(max-width:1920px)': {
      minWidth:'725px',
    },
    '@media(max-width:1600px)': {
      minWidth:'600px',
    },
    '@media(max-width:1280px)':{
      minWidth:'480px'
    },
    '@media(max-width:1024px)':{
      minWidth:'auto',
      width:'100%'
    },
    '@media(max-width:650px)': {
      minWidth: '100%'
    }
  },
  greenCardBox:{
    backgroundColor: '#9EE4A3', 
    padding: '32px', 
    borderRadius: '16px', 
    gap: "24px",
    boxSizing:'border-box',
    '@media(max-width:1920px)':{
      padding:'61.1px 41.93px',
      minHeight:'276.85px'
    },
    '@media(max-width:1600px)':{
      padding:'50.91px 34.94px',
      minHeight:'230px'
    },
    '@media(max-width:1280px)':{
      padding:'40.73px 27.95px',
      minHeight:'184px'
    },
    '@media(max-width:1024px)':{
      minHeight:'auto'
    }
  },
  blueCardBox:{
    backgroundColor: '#1185E0', 
    padding: '32px', 
    borderRadius: '16px', 
    gap: "24px", 
    color: 'white',
    boxSizing:'border-box',
    '@media(max-width:1920px)':{
      padding:'61.1px 41.93px',
      minHeight:'276.85px'
    },
    '@media(max-width:1600px)':{
      padding:'50.91px 34.94px',
      minHeight:'230px'
    },
    '@media(max-width:1280px)':{
      padding:'40.73px 27.95px',
      minHeight:'184px'
    }
  },
  hiddenDiv: {
    width: '50%',
    '@media(max-width:959px)': {
      display: 'none'
    }
  },
  connectorWrapper: {
    width: '53%',
    display: 'flex',

    alignItems: 'flex-end',
    marginTop: '6%',
    '@media(max-width:1920px)':{
      width:'31%'
    },
    '@media(max-width:959px)':{
      display:'none'
    },
  },
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 15px',
    justifyContent: 'space-between',
    width: '90vw'
  },
  signUpButton: {
    color: '#0069BC',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    fontSize: '14px',
    height: '40px',
    border: 'none'
  },
  forMenues: {
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.down(900)]: {
      display: 'none',
    }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 15px',
    justifyContent: 'space-between',
    width: '90vw'
  },
  ImgTagStyle: {
    width: '100px',
    height: '100px',
    padding: '10px'
  },
  bgImageStyle: {
    backgroundImage: `url(${imageHerosectionbackground})`,
    minHeight: '871px',
    backgroundRepeat: "round",
    backgroundSize: "cover",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '123px',
    '@media(max-width:1920px)': {
      minHeight: '1080px',
      gap: '100px'
    },
    '@media(max-width:1600px)': {
      minHeight:'900px',
      gap: '120px'
    },
    '@media(max-width: 1280px)': {
      minHeight: '720px',
      gap: '70px'
    },
    '@media(max-width: 959px)': {
      minHeight: '570px',
      gap: '52px'
    },
    '@media(max-width: 600px)': {
      minHeight: '449px',
    }
  },
  ellipsBox: {
    backgroundImage: `url(${view_ellipseHalf})`,
    backgroundSize: "794px",
    backgroundPosition: 'top center',
    backgroundRepeat: "no-repeat",
    display: 'flex',
    justifyContent: "space-between",
    width: '1169px',
    alignItems: "flex-end",
    height: '650px',
    gap: '80px',
    '@media(max-width:1920px)': {
      maxWidth: '1536px',
      width: '100%',
      height: '834px',
      alignItems: 'end',
      gap: "0",
      backgroundSize: 'contain',
      backgroundPositionX: '172px',
    },
    '@media(max-width:1600px)': {
      maxWidth: '1280px',
      height: "695px",
      gap: "0",
      alignItems: 'end',
      backgroundSize: 'contain',
      backgroundPositionX: '150px',
    },
    '@media(max-width:1280px)': {
      backgroundSize: 'contain',
      backgroundPositionX: '133px',
      alignItems: 'center',
      gap: "0",
      maxWidth: '1030px',
      height: '560px'
    },
    '@media(max-width: 1024px)': {
      backgroundImage: 'none',
      padding: '0px 20px',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      height: 'auto',
      gap: '0px'
    }
  },
  futureBox: {
    border: '2px solid #0069BC',
    borderRadius: '40px',
    padding: '24px 40px',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: 700,
    color: 'rgba(0, 105, 188, 1)',
    textAlign: 'center',
    marginTop: '-32px',
    background: 'white',
    position: 'relative',
    whiteSpace: 'nowrap',
    '@media(max-width:1920px)': {
      padding: '31px 51.67px',
      fontSize: '31px',
      borderRadius: '51.67px'
    },
    '@media(max-width:1600px)': {
      padding: '25.84px 43.06px',
      borderRadius: '43.06px',
      fontSize: '25.84px'
    },
    '@media(max-width:1280px)': {
      fontSize: '20.79px',
      lineWeight: '24.95px',
      padding: '20.79px 34.65px'
    }
  },
  titleTextMainBox: {
    maxWidth: '1170px',
    width: '100%',
    minHeight: '255px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    gap: '40px',
    '@media(max-width:600px)': {
      maxWidth: "90%"
    }
  },
  contentImgBox: {
    maxWidth: '1169px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media(max-width:1920px)': {
      maxWidth:'1545px'
    },
    '@media(max-width:1600px)': {
      maxWidth:'1287.5px'
    },
    '@media(max-width:1280px)': {
      maxWidth:'1030px',
      paddingLeft: '20px',
      paddingRight: '20px'
    },    
    '@media(max-width:1024px)': {
      gap: '20px'
    }
  },
  contentBox: {
    padding: '50px 0px',
    maxWidth: '1169px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  futureText: {
    color: '#00E644',
    fontWeight: 700,
  },
  secountPartBox: {
    width: '100%'
  },
  limitationBox: {
    background: 'linear-gradient(96.34deg, rgba(0, 230, 68, 0.25) 0%, rgba(0, 230, 68, 0.5) 100%)',
    padding: '32px',
    borderRadius: '16px',
    width: '50%'
  },
  goalBox: {
    background: 'linear-gradient(92.2deg, #0086F0 0%, #0069BC 100%)',
    padding: '32px',
    borderRadius: '16px',
    width: '50%'
  },
  limitationText: {
    fontSize: '32px',
    fontWeight: 700,
    fontFamily: "lato",
    color: '#0069BC'
  },
  goalText: {
    fontSize: '32px',
    fontWeight: 700,
    fontFamily: "lato",
    color: '#fff'
  },
  limitDescriptionTxt: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: "Roboto",
    width: '500px'
  },
  goalDescriptionTxt: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#fff",
    width: '500px'
  },
  bottomMainBox: {
    display: 'flex',
    alignItens: 'end',
    justifyContent: 'center',
    gap: '65px',
    width: '100%',
  },
  mainTextThirdPart: {
    fontSize: '48px',
    fontWeight: 600,
    fontFamily: "Roboto",
    color: "#000",
  }
}
// Customizable Area End
