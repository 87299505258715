import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import { frameShape, imgShape, navLogo } from "../../landingpage/src/assets";
import { imageHerosectionbackground, imgEllips } from "../../contentmanagement/src/assets";
import Loader from "../../../components/src/Loader.web";
import { imgFive, imgFour, imgOne, imgThree, imgTwo, imgZero,GroupArrowBig,imgBigTwo,imgBigZero,imgBigOne } from "./assets";
import Footer from "../../../components/src/Footer.web";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// Customizable Area End

import StartupInvestmentController, {
    Props,
} from "./StartupInvestmentController.web";

const styles: StyleRules = {
    // Customizable Area Start
    bgImageStyle: {
        backgroundImage: `url(${imageHerosectionbackground})`,
        height: '1080px',
        backgroundRepeat: "round",
        backgroundSize: "cover",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '102.27px',
        position: 'relative',
        '@media (max-width: 1600px)': {
            height: '900px',
            gap: '85.22px',
        },
        '@media (max-width: 1280px)': {
            height: '720px',
            gap: '72.54px'
        },
        '@media (max-width: 959px)': {
            height: '570px',
            gap: '52px'
        },
        '@media (max-width: 600px)': {
            height: '449px',
            gap: '26px'
        }
    },
    shapeImage: {
        position: 'absolute',
        right: '112.2px',
        top: '585.66px',
        '@media (max-width: 1600px)': {
            right: '93.5px',
            top: '488.05px',
        },
        '@media (max-width: 1280px)': {
            right: '75.8px',
            top: '363.96px',
        },
        '@media (max-width: 600px)': {
            top: '300px',
        }
    },
    headingDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '42.8px',
        width: '1520.56px',
        '@media (max-width: 1600px)': {
            gap: '40.66px',
            width: '1267.14px',
        },
        '@media (max-width: 1280px)': {
            gap: '36.06px',
            width: '1040px',
        }
    },
    headerMainText: {
        fontSize: '84.91px',
        lineHeight: '127.36px',
        fontWeight: 400,
        fontFamily: "lato",
        textAlign: 'center',
        color: '#fff',
        '@media (max-width: 1600px)': {
            fontSize: '70.76px',
            lineHeight: '106.13px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '58px',
            lineHeight: '87px',
        },
        '@media (max-width: 959px)': {
            fontSize: '36px',
            lineHeight: '57px',
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
            lineHeight: '37px',
            marginBottom: "0px"
        }
    },
    directlyInvestStyle: {
        margin: '166.59px 0',
        maxWidth: '1563.29px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20.44px',
        "& .directlyInvestHeading": {
            fontSize: '60px',
            lineHeight: '72px',
            fontFamily: 'Lato',
            fontWeight: 600,
            textAlign: 'center'
        },
        "& .startupInvestBox": {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '20px',
            "& .startupInvestBoxStyle": {
                display: 'flex',
                gap: '49.11px'
            }
        },
        '@media (max-width: 1600px)': {
            margin: '138.95px 0',
            maxWidth: '1302.74px',
            gap: '17.03px',
            "& .directlyInvestHeading": {
                fontSize: '50px',
                lineHeight: '60px',
            },
            "& .startupInvestBox": {
                gap: '16.91px',
                "& .startupInvestBoxStyle": {
                    gap: '40.93px !important'
                }
            },
        },
        '@media (max-width: 1280px)': {
            margin: '111.06px 0',
            maxWidth: '1042.2px',
            gap: '13.98px',
            "& .directlyInvestHeading": {
                fontSize: '40px',
                lineHeight: '48px',
            },
            "& .startupInvestBox": {
                gap: '13.33px',
                "& .startupInvestBoxStyle": {
                    gap: '32.74px !important'
                }
            },
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0',
            gap: '32.74px',
            "& .directlyInvestHeading": {
                fontSize: '40px',
            },
            "& .startupInvestBox": {
                "& img": {
                    display: 'none'
                },
                "& .startupInvestBoxStyle": {
                    flexDirection: 'column'
                }
            },
        },
        '@media (max-width: 600px)': {
            "& .directlyInvestHeading": {
                fontSize: '28px',
            },
        }
    },
    arrowImage: {
        width: '100%',
        maxWidth: '1067.23px',
        '@media (max-width: 1600px)': {
            maxWidth: '889.36px',
        },
        '@media (max-width: 1280px)': {
            maxWidth: '711.49px',
        }
    },
    simplifiedStyle: {
        maxWidth: '1545.74px',
        margin: '82.58px 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '4px',
        "& img": {
            height: '238px',
            width: '116px'
        },
        "& .simplifiedHeading": {
            fontSize: '63.38px',
            lineHeight: '76.06px',
            fontFamily: 'Lato',
            fontWeight: 600,
            textAlign: 'center'
        },
        "& .directlyInvestText": {
            display: 'flex',
            alignItems: 'center',
            marginTop: '138px'
        },
        '@media(max-width:1600px)':{
            maxWidth: '1288.12px',
            margin: '68.82px 0',
            gap: '24px',
            "& img": {
                height: '198px',
                width: '96px'
            },
            "& .simplifiedHeading": {
                fontSize: '52.82px',
                lineHeight: '63.38px',
            },
            "& .directlyInvestText": {
                marginTop: '115px'
            },
        },
        '@media(max-width:1280px)':{
            maxWidth: '1030.49px',
            margin: '55.05px 0',
            gap: '15.06px',
            "& img": {
                height: '159px',
                width: '77px'
            },
            "& .simplifiedHeading": {
                fontSize: '42.26px',
                lineHeight: '50.71px',
            },
            "& .directlyInvestText": {
                marginTop: '92px'
            },
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0',
            "& .simplifiedHeading": {
                fontSize: '40px',
            },
            "& .directlyInvestText": {
                order: 1,
                marginTop: '0px'
            },
        },
        '@media (max-width: 600px)': {
            "& .simplifiedHeading": {
                fontSize: '28px',
            },
        }
    },
    feeStructureStyle: {
        width: '100%',
        gap: '40.36px',
        flexWrap: 'nowrap',
        '@media (max-width: 1600px)': {
            gap: '33.63px',
        },
        '@media (max-width: 1280px)': {
            gap: '26.9px',
        },
        '@media (max-width: 959px)': {
            flexWrap: 'wrap',
        },
    },
    directlyInvestDetails: {
        fontSize: '23.77px',
        lineHeight: '35.65px',
        fontFamily: 'Lato',
        fontWeight: 400,
        '@media (max-width: 1600px)': {
            fontSize: '19.81px',
            lineHeight: '29.71px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '23.77px',
        }
    },
    futureOpportunitiesStyle: {
        maxWidth: '1536px',
        margin: '98.3px 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        "& .directStartupHeading": {
            fontSize: '52.51px',
            lineHeight: '63.02px',
            marginTop: '21.88px',
            fontFamily: 'Lato',
            fontWeight: 800,
            textAlign: 'center'
        },
        "& .opportunityDataStyle": {
            display: 'flex',
            gap: '39.39px',
            marginTop: '62.95px',
            flexWrap: 'nowrap'
        },
        '@media (max-width: 1600px)': {
            maxWidth: '1280px',
            margin: '79.42px 0',
            "& .directStartupHeading": {
                fontSize: '43.76px',
                lineHeight: '52.51px',
                marginTop: '17.57px',
            },
            "& .opportunityDataStyle": {
                gap: '32.82px',
                marginTop: '51.96px',
            },
        },
        '@media (max-width: 1280px)': {
            maxWidth: '1030px',
            margin: '57.65px 0',
            "& .directStartupHeading": {
                fontSize: '35.21px',
                lineHeight: '42.26px',
                marginTop: '14.45px',
            },
            "& .opportunityDataStyle": {
                gap: '26.41px',
                marginTop: '42.46px',
            },
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0px',
            "& .directStartupHeading": {
                fontSize: '32px',
            },
            "& .opportunityDataStyle": {
                flexWrap: 'wrap',
                justifyContent: 'center'
            },
        },
        '@media (max-width: 600px)': {
            "& .directStartupHeading": {
                fontSize: '24px',
            },
        }
    },
    futureOpportunitiesText: {
        fontSize: '23.63px',
        lineHeight: '28.36px',
        fontFamily: 'Lato',
        color: 'rgba(0, 105, 188, 1)',
        fontWeight: 400,
        textAlign: 'center',
        '@media (max-width: 1600px)': {
            fontSize: '19.69px',
            lineHeight: '23.63px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '19.02px',
        }
    },
    opportunityDataGrid: {
        maxWidth: '748.31px',
        width: '100%',
        padding: '30.5px 60px',
        display: 'flex',
        flexDirection: 'column',
        border:'1px solid rgba(111, 111, 112, 1)',
        gap: '20px',
        borderRadius:'12px',
        backgroundColor:'rgb(242, 248, 252)',
        '@media (max-width: 1600px)': {
            maxWidth: '623.59px',
            padding: '30.5px 40px',
        },
        '@media (max-width: 1280px)': {
            maxWidth: '501.79px',
            padding: '20px',
        },
        '@media (max-width: 959px)': {
            maxWidth: 'none'
        }
    },
    opportunityTitle: {
        fontFamily: 'Lato',
        fontWeight: 800,
        fontSize: '52.51px',
        '@media (max-width: 1600px)': {
            fontSize: '43.76px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '35.21px',
        }
    },
    futureText: {
        color: '#00E644',
        fontWeight: 800,
        fontStyle: 'italic'
    },
    opportunitiesBtn: {
        fontSize: '26.03px',
        lineHeight: '30.5px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: 'rgba(0, 105, 188, 1)',
        maxWidth: '419.48px',
        width: '100%',
        height: '72px',
        borderRadius: '15.62px',
        textTransform: 'none',
        background: 'rgba(255, 255, 255, 1)',
        '&:hover': {
            background: 'rgba(255, 255, 255, 1)',
        },
        '@media(max-width:1600px)':{
            fontSize: '21.69px',
            lineHeight: '25.42px',
            maxWidth: '349.56px',
            height: '60px',
            borderRadius: '13.01px',
        },
        '@media(max-width:1280px)':{
            fontSize: '17.78px',
            lineHeight: '20.83px',
            maxWidth: '300px',
            height: '44.77px',
            borderRadius: '10.67px',
        }
    },
    startupBox: {
        maxWidth: '404.07px',
        height: 'max-content',
        width: '100%',
        padding: '42.26px 42.26px 83.2px',
        background: 'rgba(229, 240, 248, 1)',
        borderRadius: '21.13px',
        display: 'flex',
        flexDirection: 'column',
        gap: '31.69px',
        position: 'relative',
        "&.second": {
            background: 'rgba(0, 105, 188, 1)',
            top: '-82.77px'
        },
        "&.third": {
            top: '-166.77px'
        },
        '@media (max-width: 1600px)': {
            maxWidth: '336.74px',
            padding: '35.21px 35.21px 69.33px',
            borderRadius: '17.61px',
            gap: '26.41px',
            "&.second": {
                top: '-68.73px'
            },
            "&.third": {
                top: '-138.98px'
            },
        },
        '@media (max-width: 1280px)': {
            maxWidth: '269.39px',
            padding: '28.17px 28.17px 55.46px',
            borderRadius: '14.09px',
            gap: '21.13px',
            "&.second": {
                top: '-55.18px'
            },
            "&.third": {
                top: '-111.18px'
            },
        },
        '@media (max-width: 959px)': {
            top: "0 !important",
            width: "auto",
            maxWidth:'none'
        }
    },
    startupBoxTitle: {
        fontSize: '42.26px',
        lineHeight: '50.71px',
        fontFamily: 'Lato',
        fontWeight: 800,
        color: 'rgba(0, 105, 188, 1)',
        '@media (max-width: 1600px)': {
            fontSize: '35.21px',
            lineHeight: '42.26px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '28.17px',
            lineHeight: '33.81px',
        }
    },
    startupBoxText: {
        wordWrap: 'break-word',
        fontSize: '23.77px',
        lineHeight: '35.65px',
        fontFamily: 'Lato',
        fontWeight: 400,
        '@media (max-width: 1600px)': {
            fontSize: '19.81px',
            lineHeight: '29.71px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '23.77px',
        }
    },
    ellipsBox: {
        margin: '97.63px 0',
        maxWidth: '1493.7px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        "& .ellipsBox1": {
            marginLeft: '560.85px',
            position: 'relative',
            zIndex: 1,
            "& img": {
                height: '101px',
            },
            "& .box1": {
                width: '203px'
            },
            "& .zeroImage1": {
                margin: "0px -35.8px -39.81px 78.1px"
            },
            '@media(max-width:1600px)':{
                marginLeft: '467.37px',
                "& img": {
                    height: '84px',
                },
                "& .box1": {
                    width: '169.28px'
                },
                "& .zeroImage1": {
                    margin: "0px -29.53px -33.01px 65.24px"
                },
            },
            '@media(max-width:1280px)':{
                marginLeft: '373.9px',
                "& img": {
                    height: '68px',
                },
                "& .box1": {
                    width: '135.63px'
                },
                "& .zeroImage1": {
                    margin: "0px -24.09px -27.21px 51.96px"
                },
            }
        },
        "& .ellipsBox2": {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            zIndex: 1,
            margin: '95.11px 146.73px 0px 39.31px',
            "& img": {
                height: '101px',
            },
            "& .ellipsBox22": {
                "& .box22": {
                    width: '206.39px'
                },
                "& .ellipsBox22Image": {
                    margin: "0px -35.8px -39.81px 138.1px"
                },
                '@media(max-width:1280px)':{
                    "& .box22": {
                        width: '137.62px'
                    },
                    "& .ellipsBox22Image": {
                        margin: "0px -24.09px -27.21px 91.96px !important"
                    },
                },
                '@media(max-width:1600px)':{
                    "& .box22": {
                        width: '171.71px'
                    },
                    "& .ellipsBox22Image": {
                        margin: "0px -29.53px -33.01px 115.24px"
                    },
                },
            },
            "& .ellipsBox21": {
                "& .box21": {
                    width: '230.66px'
                },
                "& .ellipsBox21Image": {
                    margin: "0px -35.8px -39.81px 45.45px"
                },
                '@media(max-width:1280px)':{
                    "& .box21": {
                        width: '153.45px'
                    },
                    "& .ellipsBox21Image": {
                        margin: "0px -24.09px -27.21px 30.19px !important"
                    },
                },
                '@media(max-width:1600px)':{
                    "& .box21": {
                        width: '192.27px'
                    },
                    "& .ellipsBox21Image": {
                        margin: "0px -29.53px -33.01px 38.03px"
                    },
                },
                '@media(max-width:959px)':{
                    "& .ellipsBox21Image": {
                        margin: '0px -24.09px -27.21px 84.19px !important'
                    }
                }
            },
            '@media(max-width:1600px)':{
                margin: '79.25px 122.28px 0px 32.76px',
                "& img": {
                    height: '84px',
                },
            },
            '@media(max-width:1280px)':{
                margin: '63.4px 97.82px 0px 26.2px',
                "& img": {
                    height: '68px',
                },
            }
        },
        "& .ellipsBox3": {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            zIndex: 1,
            margin: '124.26px 0 0',
            "& img": {
                height: '101px',
            },
            "& .ellipsBox31": {
                "& .box31": {
                    width: '201.92px'
                },
                "& .ellipsBox31Image": {
                    margin: "0px -35.8px -39.81px 32.95px"
                },
                '@media(max-width:1600px)':{
                    "& .ellipsBox31Image": {
                        margin: "0px -29.53px -33.01px 27.61px"
                    },
                    "& .box31": {
                        width: '167.99px'
                    },
                },
                '@media(max-width:1280px)':{
                    "& .ellipsBox31Image": {
                        margin: "0px -24.09px -27.21px 21.86px"
                    },
                    "& .box31": {
                        width: '134.62px'
                    },
                }
            },
            "& .ellipsBox32": {
                display: 'none'
            },
            "& .ellipsBox33": {
                "& .box33": {
                    width: '381.66px'
                },
                "& .ellipsBox33Image1": {
                    margin: "0px -35.8px -39.81px 298.85px"
                },
                "& .ellipsBox33Image2": {
                    position: 'absolute',
                    zIndex: 1,
                },
                '@media(max-width:1600px)':{
                    "& .box33": {
                        width: '317.77px'
                    },
                    "& .ellipsBox33Image1": {
                        margin: "0px -29.53px -33.01px 249.2px"
                    },
                },
                '@media(max-width:1280px)':{
                    "& .box33": {
                        width: '254.447px'
                    },
                    "& .ellipsBox33Image1": {
                        margin: "0px -24.09px -27.21px 190.13px"
                    },
                }
            },
            '@media(max-width:1600px)':{
                margin: '105.22px 0 0',
                "& img": {
                    height: '84px',
                },
            },
            '@media(max-width:1280px)':{
                margin: '84.18px 0 0',
                "& img": {
                    height: '68px',
                },
            }
        },
        '@media(max-width:1600px)':{
            margin: '80px 0',
            maxWidth: '1244.75px',
        },
        '@media(max-width:1280px)':{
            margin: '62.09px 0',
            maxWidth: '995.8px',
        },
        '@media(max-width:1200px)':{
            padding:'0 20px',
        },
        '@media (max-width: 959px)': {
            margin: '0px',
            alignItems: 'center',
            backgroundImage: 'none',
            "& .ellipsBox1": {
                order: 1,
                top: '0px',
                margin: '0px !important',
            },
            "& .ellipsBox2": {
                order: 2,
                top: '0px',
                margin: '0px !important',
                "& .ellipsBox21": {
                    marginLeft: '0px !important',
                    "& .ellipsBox21Image": {
                        margin: '0px -24.09px -27.21px 63.19px !important'
                    }
                },
                "& .ellipsBox22": {
                    display: 'none'
                }
            },
            "& .ellipsBox3": {
                order: 3,
                margin: '0px !important',
                flexDirection: 'column',
                alignItems: 'center',
                "& .ellipsBox31": {
                    marginLeft: '0px !important',
                    "& .ellipsBox31Image": {
                        margin: '0px -24.09px -27.21px 57.19px !important'
                    }
                },
                "& .ellipsBox32": {
                    display: 'block !important',
                    "& .ellipsBox32Image": {
                        margin: '0px -24.09px -27.21px 69.19px !important'
                    }
                },
                "& .ellipsBox33": {
                    "& .ellipsBox33Image1": {
                        left: '0px !important',
                        margin: '0px -24.09px -27.21px 118.19px !important'
                    },
                    "& .ellipsBox33Image2": {
                        right: 'auto !important'
                    }
                }
            },
        },
        '@media (max-width: 600px)': {
            fontSize: '24px',
            marginBottom: "0px"
        }
    },
    ellipsImage: {
        position: 'absolute',
        top: '128.47px',
        left: '160.02px',
        width: '1092.8px',
        '@media (max-width: 1600px)': {
            top: '107.06px',
            left: '133.35px',
            width: '910.67px',
        },
        '@media (max-width: 1280px)': {
            top: '85.65px',
            left: '106.68px',
            width: '728.53px',
        },
        '@media (max-width: 959px)': {
            display: 'none'
        }
    },
    futureBox: {
        border: '2px solid rgba(0, 105, 188, 1)',
        borderRadius: '105.58px',
        fontSize: '31.68px',
        lineHeight: '47.51px',
        fontFamily: 'Lato',
        fontWeight: 700,
        color: 'rgba(0, 105, 188, 1)',
        textAlign: 'center',
        background: 'white',
        position: 'relative',
        padding: '31.19px 52.76px',
        '@media (max-width: 1600px)': {
            borderRadius: '87.99px',
            fontSize: '26.4px',
            lineHeight: '39.59px',
            padding: '25.99px 44.13px',
        },
        '@media (max-width: 1280px)': {
            borderRadius: '70.39px',
            fontSize: '21.12px',
            lineHeight: '31.68px',
            padding: '21.12px 35.19px',
        }
    },
    frameImg: {
        width: '539.14px',
        height: '527.92px',
        position: 'absolute',
        bottom: '0',
        left: '437.18px',
        zIndex: 1,
        '@media (max-width: 1600px)': {
            width: '449.29px',
            height: '439.94px',
            left: '364.32px',
        },
        '@media (max-width: 1280px)': {
            width: '359.43px',
            height: '351.95px',
            left: '291.45px',
        },
        '@media (max-width: 959px)': {
            display: 'none'
        }
    },
    logoImg: {
        width: '292.24px',
        left: '561.24px',
        bottom: '158.39px',
        position: 'absolute',
        zIndex: 1,
        '@media (max-width: 1600px)': {
            width: '243.54px',
            left: '467.7px',
            bottom: '131.99px',
        },
        '@media (max-width: 1280px)': {
            width: '194.83px',
            left: '374.16px',
            bottom: '105.59px',
        },
        '@media (max-width: 959px)': {
            position: 'static',
            left: 0,
            padding: "0px"
        }
    },
    textStyle: {
        fontSize: '23.63px',
        color: 'rgba(111, 111, 112, 1)',
        fontWeight: 500,
        fontFamily: 'Roboto',
        '@media (max-width: 1600px)': {
            fontSize: '19.69px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
        }
    },
    Imgnumbers :{
        margin: '0px -18px -100px 30px',
        '@media (max-width: 1600px)': {
            margin: '0px -18px -85px 30px'
        },
        '@media (max-width: 1280px)': {
            margin: '0px -18px -67px 22px'
        }
    },
    FundBox :{
        position: 'relative',
        backgroundColor: '#F2F8FC',
        alignContent:"end",
        borderRadius: '21.13px',
        overflow: 'hidden',
        padding:'82.68px 40.26px 28.93px',
        '@media (max-width: 1600px)': {
            borderRadius: '17.61px',
            padding:'68.39px 33.21px 23.61px',
        },
        '@media (max-width: 1280px)': {
            borderRadius: '14.09px',
            padding:'55.11px 26.17px 19.29px',
        },
        '@media (max-width: 959px)': {
            paddingTop:'35px',
        }
    },
    fundBoxText: {
        fontSize: '42.26px',
        lineHeight: '63.38px',
        fontFamily: 'Lato',
        fontWeight: 600,
        '@media (max-width: 1600px)': {
            fontSize: '35.21px',
            lineHeight: '52.82px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '28.17px',
            lineHeight: '42.26px',
        },
    },
    investBtn: {
        fontSize: '23.63px',
        lineHeight: '27.69px',
        fontFamily: "Roboto",
        fontWeight: 700,
        color: '#ffffff',
        maxWidth: '300px',
        width: '100%',
        height: '72px',
        borderRadius: '15.75px',
        marginTop: '48.48px',
        textTransform: 'none',
        background: 'rgba(0, 105, 188, 1)',
        '&:hover': {
            background: 'rgba(0, 105, 188, 1)',
        },
        '@media (max-width: 1600px)': {
            fontSize: '19.69px',
            lineHeight: '23.08px',
            maxWidth: '250px',
            height: '60px',
            borderRadius: '13.13px',
            marginTop: '45.4px',
        },
        '@media (max-width: 1280px)': {
            fontSize: '15.85px',
            lineHeight: '18.57px',
            maxWidth: '200px',
            height: '48px',
            borderRadius: '10.56px',
            marginTop: '40.1px',
        }
    },
    containerStyle: {
        display: "flex",
        justifyContent: "center",
        '@media (max-width: 959px)': {
            padding: "0px",
            margin: '70px 0px !important'
        },
    }
    // Customizable Area End
};

export class StartupInvestmentWeb extends StartupInvestmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        const { classes } = this.props;
        const { startupInvestData, opportunityData, feeStructureData } = this.state;

        return (
            <Paper style={{ overflowX: 'hidden', minHeight: '100vh' }}>
                <Loader loading={this.state.loading} />
                <Box className={classes.bgImageStyle}>
                    <AppHeader navigation={this.props.navigation} />
                    <img src={imgShape} className={classes.shapeImage} />
                    <div className={classes.headingDiv}>
                        <Typography className={classes.headerMainText}> Experience the benefits of <br/> <span className={classes.futureText}>Direct Startup Investing </span></Typography>
                        <Button className={classes.opportunitiesBtn} onClick={this.startupInvestmentDetails}>View Available Opportunities</Button>
                    </div>
                </Box>

                <Grid container className={classes.containerStyle}>
                    <Grid className={classes.ellipsBox}>
                        <img src={imgEllips} className={classes.ellipsImage} />
                        <Box className="ellipsBox1">
                            <img src={imgZero} className="zeroImage1" />
                            <img src={imgOne} style={{ position: 'absolute', zIndex: 1 }} />
                            <Box className={`${classes.futureBox} box1`}>
                                High potential for returns
                            </Box>
                        </Box>
                        <Box className="ellipsBox2">
                            <Box className="ellipsBox21">
                                <img src={imgZero} className="ellipsBox21Image" />
                                <img src={imgTwo} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={`${classes.futureBox} box21`}>
                                    Early access to innovative ideas
                                </Box>
                            </Box>
                            <Box className="ellipsBox22">
                                <img src={imgZero} className="ellipsBox22Image" />
                                <img src={imgFour} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={`${classes.futureBox} box22`}>
                                    Portfolio Diversification
                                </Box>
                            </Box>
                        </Box>
                        <Box className="ellipsBox3">
                            <Box className="ellipsBox31">
                                <img src={imgZero} className="ellipsBox31Image" />
                                <img src={imgThree} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={`${classes.futureBox} box31`}>
                                    Value beyond capital
                                </Box>
                            </Box>
                            <Box className="ellipsBox32">
                                <img src={imgZero} className="ellipsBox32Image" />
                                <img src={imgFour} style={{ position: 'absolute', zIndex: 1 }} />
                                <Box className={classes.futureBox} style={{ maxWidth: '156px' }}>
                                    Portfolio Diversification
                                </Box>
                            </Box>
                            <Box className="ellipsBox33">
                                <img src={imgZero} className="ellipsBox33Image1" />
                                <img src={imgFive} className="ellipsBox33Image2" />
                                <Box className={`${classes.futureBox} box33`}>
                                    Active participation in the entrepreneurial ecosystem
                                </Box>
                            </Box>
                        </Box>
                        <img src={frameShape} className={classes.frameImg} />
                        <img src={navLogo} className={classes.logoImg} />
                    </Grid>
                </Grid>

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.directlyInvestStyle}>
                        <Typography className="directlyInvestHeading">
                            Why directly invest in <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>Startups</span>?
                        </Typography>
                        <Grid item className="startupInvestBox">
                            <img src={GroupArrowBig} alt="" className={classes.arrowImage} />
                            <Box className="startupInvestBoxStyle">
                                <Box className={classes.startupBox}>
                                    <Typography className={classes.startupBoxTitle} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[0]?.sub_title}
                                    </Typography>
                                    <Typography className={classes.startupBoxText} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[0]?.content}
                                    </Typography>
                                </Box>
                                <Box className={`${classes.startupBox} second`}>
                                    <Typography className={classes.startupBoxTitle} style={{ color: 'rgba(255, 255, 255, 1)' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[1]?.sub_title}
                                    </Typography>
                                    <Typography className={classes.startupBoxText} style={{ color: '#FFFFFF' }} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[1]?.content}
                                    </Typography>
                                </Box>
                                <Box className={`${classes.startupBox} third`}>
                                    <Typography className={classes.startupBoxTitle} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[2]?.sub_title}
                                    </Typography>
                                    <Typography className={classes.startupBoxText} >
                                        {startupInvestData?.titles?.data[0]?.attributes?.title_contents[2]?.content}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {feeStructureData != undefined ?
                    <Grid container className={classes.containerStyle}>
                        <Grid container className={classes.simplifiedStyle}>
                            <Typography className="simplifiedHeading">
                                Simplified <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 700 }}>Fee Structure</span>
                            </Typography>

                            <div>
                                <Grid container className={classes.feeStructureStyle}>
                                    
                                    <Grid item md={6} xs={12} className={classes.feeStructureBlueBox}>
                                        <img src={imgBigZero} className={classes.Imgnumbers} />
                                        <img src={imgBigOne} style={{ position: 'absolute', zIndex: 1, marginTop: '5px' }} />
                                        <Box className={classes.FundBox}>
                                        <Typography className={classes.fundBoxText}>
                                            Invest without <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>upfront fees</span> - pay only when you invest.
                                        </Typography>
                                        </Box>

                                    </Grid>
                                    
                                    <Grid item md={6} xs={12} className="directlyInvestText">
                                    <Typography className={classes.directlyInvestDetails} dangerouslySetInnerHTML={{ __html: feeStructureData[0]?.attributes?.content }}></Typography>
                                    
                                    </Grid>
                                </Grid>
                                
                                <Grid container className={classes.feeStructureStyle}>
                                    
                                    <Grid item md={6} xs={12} className="directlyInvestText">
                                    <Typography className={classes.directlyInvestDetails} dangerouslySetInnerHTML={{ __html: feeStructureData[1]?.attributes?.content }}></Typography>
                                    
                                    </Grid>
                                
                                    <Grid item md={6} xs={12} className={classes.feeStructureBlueBox}>
                                        <img src={imgBigZero} className={classes.Imgnumbers} />
                                        <img src={imgBigTwo} style={{ position: 'absolute', zIndex: 1 }} />
                                        <Box className={classes.FundBox}>
                                        <Typography className={classes.fundBoxText}>
                                            <span style={{ fontStyle: 'italic', color: 'rgba(0, 105, 188, 1)', fontWeight: 800 }}>Transparent fees</span> for informed investments - know the fees before you invest.
                                        </Typography>
                                        </Box>

                                    </Grid>
                                    
                                </Grid>
                            </div>
                            
                        </Grid>
                    </Grid>
                    : ''}

                <Grid container className={classes.containerStyle}>
                    <Grid container className={classes.futureOpportunitiesStyle}>
                        <Typography className={classes.futureOpportunitiesText}>
                            From Our Past Successes to Your Future Opportunities
                        </Typography>
                        <Typography className="directStartupHeading">
                            Our Direct Startup Investments
                        </Typography>
                        <Grid className="opportunityDataStyle">
                            {opportunityData?.slice(0, 2).map((item: any) => (
                                <Grid className={classes.opportunityDataGrid}>
                                    <Grid style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                                        <Typography className={classes.opportunityTitle}>{item.attributes.company.data[0]?.attributes.company_name}</Typography>
                                        <Typography className={classes.textStyle}>{item.attributes.company.data[0]?.attributes.about}</Typography>
                                    </Grid>
                                    <Grid style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>

                                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Minimum Investment</Typography>
                                                <Typography className={classes.textStyle} >${this.numberWithCommas(item.attributes.minimum_investment)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Allocation</Typography>
                                                <Typography className={classes.textStyle}>${this.numberWithCommas(item.attributes.allocation)}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}  >Round</Typography>
                                                <Typography className={classes.textStyle}>{item.attributes.round}</Typography>
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography className={classes.textStyle}>Estimated Fees</Typography>
                                                <Typography className={classes.textStyle}>{item.attributes.estimated_fee}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
                                            <Typography className={classes.textStyle}>View Fund Info</Typography>
                                            <Grid style={{ cursor: 'pointer', backgroundColor: 'rgba(0, 105, 188, 1)', borderRadius: '50%', padding: '6px', color: 'white' }} onClick={() => window.location.href = `/OpportunitiesDetailsWeb/${item.id}`}>
                                                <ArrowForwardIcon />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            ))}
                        </Grid>
                        <Button className={classes.investBtn} onClick={this.startupInvestmentDetails} data-test-id="investNowBtn">Invest Now</Button>
                    </Grid>
                </Grid>

                <Footer navigation={this.props.navigation}/>
            </Paper >
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(styles)(StartupInvestmentWeb);
// Customizable Area End
